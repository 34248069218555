import React from "react";
import { Route, Switch } from "react-router-dom";
import List from "./routes/list";
import Detail from "./routes/detail";
import NotFound from "components/Errors/NotFound";

const Router = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}`} component={List} exact />
            <Route path={`${match.url}/:mailer_id`} component={Detail} />

            <Route component={NotFound} />
        </Switch>
    );
};

export default Router;
