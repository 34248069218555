import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { useStores } from "modules/stores";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import IntlMessages from "util/IntlMessages";
import Error from "components/Errors/Error";
import EditField from "components/table/EditField";
import CircularProgress from "@material-ui/core/CircularProgress";
import languageData from "components/LanguageSwitcher/data";

const SettingsDialog = ({ onClose }) => {
    const { usersStore } = useStores();

    const localStore = useLocalStore(() => ({
        specification: { moduleName: "Users" },
    }));

    const settingsFields = [
        {
            name: "lang_id",
            type: "number",
            formType: "radio",
            link: { list: languageData },
        },
        {
            name: "current_password",
            type: "password",
            max: 32,
        },
        {
            name: "new_password",
            type: "password",
            max: 32,
        },
        {
            name: "new_password2",
            type: "password",
            max: 32,
        },
    ];

    const [form, setForm] = useState(null);

    useEffect(() => {
        fetchSettings();
    }, []);

    // useEffect(async () => {  //it's not working :(

    const fetchSettings = async () => {
        const settings = await usersStore.fetchSettings(usersStore.me.id);
        setForm(settings);
    };

    const handleFormChange = (field, value, ev) => {
        setForm({ ...form, [field.name]: value });
    };
    const handleDialogSave = async () => {
        console.log(22222, JSON.stringify(form));
        await usersStore.saveSettings(form);
        if (!usersStore.savingSettingsError) {
            onClose();
        }
    };
    const handleDialogClose = async () => {
        onClose();
    };

    return useObserver(() => {
        if (!usersStore) return "";
        if (form === null || form === undefined) {
            return "";
        }
        return (
            <Dialog open onClose={onClose} TransitionComponent={Slide}>
                <DialogTitle>
                    <IntlMessages id="popup.settings" />
                </DialogTitle>
                <DialogContent>
                    {usersStore.savingSettingsError && <Error>{usersStore.savingSettingsError}</Error>}

                    {usersStore.fetchingSettingsError && <Error>{usersStore.fetchingSettingsError}</Error>}

                    {settingsFields.map((field) => {
                        return (
                            <EditField
                                store={localStore}
                                field={field}
                                value={form[field.name]}
                                onChange={handleFormChange}
                            />
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="default">
                        <IntlMessages id="dialog.close" />
                    </Button>
                    <Button onClick={handleDialogSave} color="primary">
                        <IntlMessages id="dialog.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    });
};

export default SettingsDialog;
