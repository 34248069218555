import React, { useEffect, useState } from "react";
import { reaction } from "mobx";
import { useDispatch, useSelector } from "react-redux";
import { useStores } from "modules/stores";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "components/Errors/Error";
import EditField from "components/table/EditField";
import LogoVertical from "assets/components/LogoVertical";
/*
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from 'actions/Auth';

 */

const SignUp = (props) => {
    const { authStore } = useStores();
    const history = useHistory();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const utm_source = localStorage.getItem("utm_source") ? localStorage.getItem("utm_source") : "";
    const utm_term = localStorage.getItem("utm_term") ? localStorage.getItem("utm_term") : "";
    const utm_content = localStorage.getItem("utm_content") ? localStorage.getItem("utm_content") : "";
    const utm_campaign = localStorage.getItem("utm_campaign") ? localStorage.getItem("utm_campaign") : "";

    useEffect(() => {
        //alert("E"+email);

        reaction(
            () => authStore.isSignUpSuccess,
            (isSignUpSuccess) => {
                if (authStore.isVerifyRequired) {
                    history.push(`/auth/email/verify/`);
                } else if (isSignUpSuccess) {
                    //authStore.userSignIn({email, password});
                    //history.push(`/auth/email/signin${props.match.params.code ? `/${props.match.params.code}` : ""}`);
                }
            },
        );
    }, []);

    return useObserver(() => {
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <LogoVertical />

                    <div className="app-login-content">
                        <div className="mb-2">
                            <Link
                                to={`/auth/email/signin${props.match.params.code ? `/${props.match.params.code}` : ""}`}
                            >
                                &laquo;
                                <IntlMessages id="Auth.back" />
                            </Link>
                        </div>

                        <div className="app-login-header mb-4">
                            <h1>
                                <IntlMessages id="Auth.signUpTitle" />
                            </h1>
                        </div>

                        {authStore.signUpError && (
                            <Error>
                                <IntlMessages
                                    id={`Auth.${authStore.signUpError}`}
                                    defaultMessage={authStore.signUpError}
                                />
                            </Error>
                        )}

                        <div className="app-login-form">
                            <form method="post" action="/">
                                <EditField
                                    field={{ name: "name" }}
                                    onChange={(field, value, event) => setName(value)}
                                    store={{ specification: { moduleName: "Auth" } }}
                                    value={name}
                                    variant="standard"
                                />

                                <EditField
                                    field={{ name: "email", type: "email" }}
                                    onChange={(field, value, event) => setEmail(value)}
                                    store={{ specification: { moduleName: "Auth" } }}
                                    value={email}
                                    variant="standard"
                                />

                                <EditField
                                    field={{ name: "password", type: "password" }}
                                    onChange={(field, value, event) => setPassword(value)}
                                    store={{ specification: { moduleName: "Auth" } }}
                                    value={password}
                                    variant="standard"
                                />

                                <div
                                    className="mb-3 d-flex align-items-center justify-content-between"
                                    style={{ paddingTop: 20 }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            const res = await authStore.userSignUp({
                                                name,
                                                email,
                                                password,
                                                utm: { utm_source, utm_term, utm_content, utm_campaign },
                                                code: props.match.params.code,
                                            });
                                            if (res) {
                                                authStore.userSignIn({ email, password });
                                            }
                                        }}
                                        color="primary"
                                    >
                                        <IntlMessages id="Auth.register" />
                                    </Button>
                                    {/* mobile not good
                    <Link to="/auth/signin">
                      <IntlMessages id="Auth.alreadyMember"/>
                    </Link>
                    */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {authStore.isSignUpInProcess && (
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    });
};

export default SignUp;
