import {
    observable,
    action,
    runInAction,
    computed,
    reaction,
    decorate
} from 'mobx';
import Cookies from 'js-cookie';
import WebApi, { getErrorMessage } from 'modules/WebApi';
//import { CAPTCHA_TYPE_RECAPTCHA } from 'services/webapi-client/constants';
import { differenceInCalendarDays, fromUnixTime } from 'date-fns';
import jwt from 'jsonwebtoken';
import AuthApi from "./api"

class AuthStore implements StoreInterface {
    rootStore: RootStoreInterface;

    apiClient: WebApi;

    isLoginInProgress: string | undefined;

    loginError: string | undefined;


    signUpError: string | undefined;

    logoutError: object | undefined;

    isRequestPasswordEmailError: string | undefined;


    isClientAccessTokenFetched = false;

    accessTokenDecoded: AccessTokenDecoded = {
        role: undefined,
        exp: undefined,
        iat: undefined,
        issuer: undefined,
        jti: undefined,
        ownerId: undefined,
        type: undefined,
        coId: undefined,
        ownUid: undefined,
    };

    isDomainInfoFetched = false;
    domainInfo = undefined;
    isFetchDomainInfoInProgress = true;
    fetchDomainInfoError = "";



    isLogoutInProcess = false;

    isSignUpInProcess = false;

    isVerifyRequired = false;

    isSignUpSuccess = false;

    isRequestPasswordEmailInProcess = false;

    isRequestPasswordEmailSuccess = false;

    isAccountActivation = false;

    accountActivationError: string | undefined = undefined;

    refreshingTokenError: any;

    fetchingAccessTokenInfoError: any;

    isResetPasswordInProcess = false;

    isResetPasswordSuccess = false;

    isResetPasswordError: string | undefined = undefined;

    isUnderImpersonate = false;

    impersonateError: any;

    isFetchingCustomerToken = false;
    isResettingPassword = false;

    fetchingCustomerTokenError = undefined;

    constructor(rootStore: RootStoreInterface) {
        this.rootStore = rootStore;
        this.apiClient = new AuthApi(rootStore.apiClient);

        // handle failed access token info fetching
        reaction(
            () => this.fetchingAccessTokenInfoError,
            (fetchingAccessTokenInfoError) => {
                console.log("V5");
                if (fetchingAccessTokenInfoError) {
                    this.refreshAccessToken();
                }
            },
        );

        // handle failed access token refreshing
        reaction(
            () => this.refreshingTokenError,
            (refreshingTokenError) => {
                console.log("V4");
                if (refreshingTokenError) {
                    this.fetchClientAccessToken();
                }
            },
        );

        // hanlde successfull login and logout
        reaction(
            () => this.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) {
                    // clear errors after successfull authentication
                    this.fetchingAccessTokenInfoError = undefined;
                    this.refreshingTokenError = undefined;

                    // set jti as csrf verification param
                    const { jti } = this.accessTokenDecoded;
                    if (jti) {
                        this.rootStore.apiClient.setJti(jti);
                    }
                } else {
                    // run authentication after logout or something that cleared accessTokenDecoded
                    //  this.authenticate();
                }
            },
        );

        // hanlde jti changed
        reaction(
            () => this.accessTokenDecoded.jti,
            (jti) => {
                // set jti as csrf verification param
                if (jti) {
                    this.rootStore.apiClient.setJti(jti);
                }
            },
        );

        // handle soon access token expiration time
        reaction(
            () => this.accessTokenDecoded.exp,
            (exp) => {
                if (exp) {
                    const expDate = fromUnixTime(parseInt(exp, 10));
                    if (differenceInCalendarDays(expDate, new Date()) < 2) {
                        // refresh access token if it expires in less than 2 days
                        this.refreshAccessToken();
                    }
                }
            },
        );

        this.fetchDomainInfo(document.location.host);

    }

    async openPersonalArea(id) {
        runInAction(() => {
            this.isFetchingCustomerToken = true;
            this.fetchingCustomerTokenError = undefined;
        });
        try {
            const token = await this.apiClient.signInByRole('customer', id);
            if (!token.access_token) return;
            localStorage.setItem('access_token', token.access_token);
            const url = (document?.location?.origin && document?.location?.origin.indexOf("localhost") < 0 ? `${document.location.origin}/` : process.env.REACT_APP_API_BASE_URL.split('api')[0]) + 'customer/';
            window.open(url)
        } catch (e) {
            console.error(e);
            runInAction(() => {
                this.fetchingCustomerTokenError = e.message;
            });
        }
        runInAction(() => {
            this.isFetchingCustomerToken = false;
        });
    }

    async resetPasswordAndSendAdmin(userId) {

        this.isResettingPassword = true;
        await this.apiClient.resetPasswordAndSendAdmin(userId);
        runInAction(() => {
            this.isResettingPassword = false;
        });

    }


    async fetchDomainInfo(domain): Promise<void> {
        if (this.isDomainInfoFetched) return;
        this.isDomainInfoFetched = true;

        runInAction(() => {
            this.isFetchDomainInfoInProgress = true;
            this.fetchDomainInfoError = undefined;
        });

        try {
            const data = await this.apiClient.domainInfo(domain);


            runInAction(() => {
                this.isFetchDomainInfoInProgress = false;
                this.domainInfo = data;
            });
        } catch (err) {
            runInAction(() => {
                this.isFetchDomainInfoInProgress = false;
                this.fetchDomainInfoError = err;
            });
        }
    }



    async userSignIn(props): Promise<void> {
        runInAction(() => {
            this.isLoginInProgress = true;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signIn(props);

            runInAction(() => {
                this.isLoginInProgress = false;
                this.setAuthorizationToken(result.access_token)
            });
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.loginError = err;
            });
        }
    }


    async userSignInByPhone(phone): Promise<void> {
        phone = "+" + phone.replace(/[^0-9]/g, '').replace(/^8/g, '7');
        runInAction(() => {
            this.isLoginInProgress = true;
            this.isSigninByPhoneSmsSent = false;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signInByPhone(phone);

            runInAction(() => {
                this.isLoginInProgress = false;
                this.isSigninByPhoneSmsSent = true;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.isSigninByPhoneSmsSent = false;
                this.loginError = err;
            });
        }
    }


    async userSignInByPhoneCheck(phone, smsCode): Promise<void> {
        phone = "+" + phone.replace(/[^0-9]/g, '').replace(/^8/g, '7');

        runInAction(() => {
            this.isLoginInProgress = true;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signInByPhoneCheck(phone, smsCode);
            runInAction(() => {
                this.setAuthorizationToken(result.access_token)
                this.isLoginInProgress = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.loginError = err;
            });
        }
    }




    async userVerify(uuid): Promise<void> {
        runInAction(() => {
            this.isVerifyInProgress = true;
            this.verifyError = undefined;
        });

        try {
            const result = await this.apiClient.verify(uuid);

            runInAction(() => {
                this.isVerifyInProgress = false;
                this.setAuthorizationToken(result.access_token)
            });
        } catch (err) {
            runInAction(() => {
                this.isVerifyInProgress = false;
                this.verifyError = err;
            });
        }
    }

    async userSignInGoogleOAuth(clientId, tokenId): Promise<void> {
        runInAction(() => {
            this.isLoginInProgress = true;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signInByGoogleOAuth(clientId, tokenId);

            runInAction(() => {
                this.isLoginInProgress = false;
                this.setAuthorizationToken(result.access_token);
                document.location.reload();
            });
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.loginError = err;
            });
        }
    }

    async userSignInFacebookOAuth(clientId, tokenId): Promise<void> {
        runInAction(() => {
            this.isLoginInProgress = true;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signInByFacebookOAuth(clientId, tokenId);

            runInAction(() => {
                this.isLoginInProgress = false;
                this.setAuthorizationToken(result.access_token)
            });
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.loginError = err;
            });
        }
    }



    async userSignInVkOAuth(clientId, redirectURL, tokenId): Promise<void> {
        if (this.isLoginInProgress) return;

        runInAction(() => {
            this.isLoginInProgress = true;
            this.loginError = undefined;
        });

        try {
            const result = await this.apiClient.signInByVkOAuth(clientId, redirectURL, tokenId);

            runInAction(() => {
                this.isLoginInProgress = false;
                this.setAuthorizationToken(result.access_token);
            });
            return result;
        } catch (err) {
            runInAction(() => {
                this.isLoginInProgress = false;
                this.loginError = err;
            });
        }
    }

    async checkAuth() {
        if (localStorage.getItem("admin_access_token")) {
            await this.setAuthorizationToken(localStorage.getItem("admin_access_token"));
        }
    }

    async setAuthorizationToken(token) {

        localStorage.setItem("admin_access_token", token);
        document.cookie = `USER_AUTHORIZED=1;max-age=31536000;path=/;`;

        const status = await this.rootStore.apiClient.checkToken();
        if (status) {
            this.rootStore.userAuthorized();;
            runInAction(() => {
                this.accessTokenDecoded = this.decodeAccessToken(token);
                //this.isAuthenticated = true;
            });
            return true;
        } else {
            localStorage.removeItem("admin_access_token", token);
            document.cookie = `USER_AUTHORIZED=1;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
            return false;
        }

        //this.rootStore.apiClient.setToken(token);




        //this.rootStore.modulesStore.webSocketStore.setupWebSocket();
    }



    async userSignUp(props): Promise<void> {
        runInAction(() => {
            this.isSignUpInProcess = true;
            this.isSignUpSuccess = false;
            this.signUpError = undefined;
            this.isVerifyRequired = undefined;
            this.isVerifyInProgress = false;
            this.verifyError = "";
        });


        try {
            const result = await this
                .apiClient
                .signUp({
                    ...props
                });


            runInAction(() => {
                this.isVerifyRequired = !result.verified;
                this.isSignUpSuccess = true;
                this.isSignUpInProcess = false;
            });

            return result;
        } catch (err) {
            runInAction(() => {
                this.signUpError = err;
                this.isSignUpInProcess = false;
            });
        }
    }



    async userSignOut(): Promise<void> {
        this.isLogoutInProcess = true;
        this.logoutError = undefined;
        try {
            runInAction(() => {
                this.accessTokenDecoded = undefined;
                this.rootStore.modulesStore.usersStore.me = {};
                //this.rootStore.apiClient.setToken("");
                //localStorage.removeItem("admin_access_token");
                localStorage.clear();
                document.cookie = `USER_AUTHORIZED=1;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
                this.rootStore.resetStores();
            });
        } catch (err) {
            alert(err);
            runInAction(() => {
                this.logoutError = err;
            });
        }

        runInAction(() => {
            this.isLogoutInProcess = false;
        });
    }



    async userRequestResetPassword(props: { email: string; backUrl?: string }): Promise<void> {
        const { email, backUrl } = props;
        runInAction(() => {
            this.isRequestPasswordEmailInProcess = true;
            this.isRequestPasswordEmailSuccess = false;
            this.isRequestPasswordEmailError = undefined;
        });

        try {
            await this
                .apiClient
                .requestResetPassword({
                    email,
                    backUrl,
                });

            runInAction(() => {
                this.isRequestPasswordEmailSuccess = true;
                this.isRequestPasswordEmailInProcess = false;
            });
        } catch (err) {
            runInAction(() => {
                this.isRequestPasswordEmailError = err;
                this.isRequestPasswordEmailInProcess = false;
            });
        }
    }



    /**
     * Is there any access token (client's or user's)
     */
    get isAuthenticated(): boolean {
        console.log("U:", JSON.stringify(this.accessTokenDecoded));
        return this.accessTokenDecoded && !!this.accessTokenDecoded.role;
    }



    async requestResetPassword(props: { email: string; captchaValue?: string, backUrl?: string }): Promise<void> {
        const { email, captchaValue, backUrl } = props;
        this.isRequestPasswordEmailInProcess = true;
        this.isRequestPasswordEmailSuccess = false;
        this.isRequestPasswordEmailError = undefined;

        try {
            await this
                .apiClient
                .user
                .requestResetPassword({
                    email,
                    backUrl: backUrl || `${window.location.origin}/login/new-password`,
                    //                    captchaType: CAPTCHA_TYPE_RECAPTCHA,
                    captchaValue,
                });

            runInAction(() => {
                this.isRequestPasswordEmailSuccess = true;
            });
        } catch (err) {
            runInAction(() => {
                const errorMessage = err;
                switch (errorMessage) {
                    case 'Not found':
                        this.isRequestPasswordEmailError = 'Email not found';
                        break;
                    default:
                        this.isRequestPasswordEmailError = errorMessage;
                }
            });
        }

        runInAction(() => {
            this.isRequestPasswordEmailInProcess = false;
        });
    }

    /*
    async signUpByEmailPassword(props: {
        email: string;
        password: string;
        captchaValue: string;
        backUrl: string;
        refererUID?: number;
    }): Promise<void> {
        const {
                  email,
                  password,
                  captchaValue,
                  backUrl,
                  refererUID,
              } = props;

        this.isSignUpInProcess = true;
        this.isSignUpSuccess = false;
        this.signUpError = undefined;

        try {
            const { status } = await this
                .apiClient
                .auth
                .signUpByPassword({
                    email,
                    password,
                    backUrl,
                    captchaType: CAPTCHA_TYPE_RECAPTCHA,
                    captchaValue,
                    refererUID,
                });

            switch (status) {
                case 201:
                    // New user created
                    runInAction(() => {
                        this.isSignUpSuccess = true;
                    });
                    break;
                case 200:
                    // Already signed up
                    await this.loginByPassword(props);
                    break;
                default:
                    throw new Error('Bad response status code');
            }

            runInAction(() => {
                this.isSignUpInProcess = false;
            });
        } catch (err) {
            runInAction(() => {
                this.signUpError = getErrorMessage(err);
                this.isSignUpInProcess = false;
            });
        }
    }
    */

    async resetPassword(props: {
        password: string;
        code: string;
        captchaValue: string;
    }): Promise<void> {
        const { password, code, captchaValue } = props;
        this.isResetPasswordInProcess = true;
        this.isResetPasswordSuccess = false;
        this.isResetPasswordError = undefined;

        try {
            await this
                .apiClient
                .user
                .resetPassword({
                    password,
                    code,
                    captchaValue,
                    //captchaType: CAPTCHA_TYPE_RECAPTCHA,
                });

            runInAction(() => {
                this.isResetPasswordSuccess = true;
            });
        } catch (err) {
            runInAction(() => {
                this.isResetPasswordError = err;
            });
        }

        runInAction(() => {
            this.isResetPasswordInProcess = false;
        });
    }


    decodeAccessToken(accessToken: string) {
        try {
            const accessTokenDecoded = jwt.decode(accessToken);
            if (!accessTokenDecoded || typeof accessTokenDecoded !== 'object') {
                return {};
            }

            const { exp, expiredAt } = accessTokenDecoded;
            if (!exp && !expiredAt) {
                return {};
            }

            if (exp > Math.floor(Date.now() / 1000) || expiredAt > Math.floor(Date.now() / 1000)) {
                // not expired
                return accessTokenDecoded;
            }

            // expired
            return {};
        } catch (err) {
            // invalid jwt
            return {};
        }
    }
}



decorate(AuthStore, {
    apiClient: observable,
    loginError: observable,
    signUpError: observable,
    logoutError: observable,
    isRequestPasswordEmailError: observable,
    accessTokenDecoded: observable,
    isLoginInProgress: observable,
    isLogoutInProcess: observable,
    isSignUpInProcess: observable,
    isSignUpSuccess: observable,
    isRequestPasswordEmailInProcess: observable,
    isRequestPasswordEmailSuccess: observable,
    isAccountActivation: observable,
    accountActivationError: observable,
    fetchingAccessTokenInfoError: observable,
    isResetPasswordInProcess: observable,
    isResetPasswordSuccess: observable,
    isResetPasswordError: observable,
    isUnderImpersonate: observable,
    impersonateError: observable,

    isAuthenticated: computed,

    userVerify: action,
    isVerifyInProgress: observable,
    isVerifyRequired: observable,
    verifyError: observable,



    isSigninByPhoneSmsSent: observable,
    userSignInByPhone: action,
    userSignInByPhoneCheck: action,

    fetchDomainInfo: action,
    domainInfo: observable,
    isFetchDomainInfoInProgress: observable,
    fetchDomainInfoError: observable,

    fetchClientAccessToken: action,
    refreshAccessToken: action,
    refreshingTokenError: observable,
    fetchAccessTokenInfo: action,
    authenticate: action,
    impersonate: action,
    loginByPassword: action,
    loginByActivationCode: action,
    logoutOrEscapeImpersonate: action,
    logout: action,
    requestResetPassword: action,
    signUpByPassword: action,
    resetPassword: action,
    escapeImpersonate: action
});


export default AuthStore;
