import FileSaver from "file-saver";
import { Base64 } from "js-base64";
// export function

export function currentGMTTime() {
    return new Date().toUTCString().replace("GMT", "");
}

export function saveFile(filename, data) {
    FileSaver.saveAs(data, filename);
}

export function saveFileBase64(filename, data) {
    const decoded = Base64.atob(data);

    const byteArray = new Uint8Array(decoded.length);
    for (let i = 0; i < byteArray.length; i++) {
        byteArray[i] = decoded.charCodeAt(i);
    }
    const textFileAsBlob = new Blob([byteArray], { type: "application/octet-binary" });
    saveFile(filename, textFileAsBlob);
}

export function isObjectsEqual(object1, object2) {
    if ((!object1 || !Object.keys(object1).length) && (!object2 || !Object.keys(object2).length)) return true;

    if (!isObject(object1) || !isObject(object2)) {
        return object1 == object2;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !isObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

export function isObject(object) {
    return object != null && typeof object === "object";
}

export function cloneObject(object) {
    return JSON.parse(JSON.stringify(object));
}
