import React from 'react';
import { configure, reaction } from 'mobx';
import WebApi from './WebApi';

//import { RootStoreInterface } from 'interfaces';
import { decorate, observable, action, runInAction } from 'mobx';
import UsersStore from './Users/stores/UsersStore';
import AuthStore from './Auth/stores/AuthStore';
//import InfoStore from './Auth/stores/InfoStore';
import SpecificationsStore from './Specifications/stores/SpecificationsStore';
import WebSocketStore from './WebSockets/stores/WebSocketsStore';
import MailerStore from './Mailer/stores/MailerStore';


/*
import PartnerStore from './PartnerStore/index';
import LegacyRoutesStore from './LegacyRoutesStore';
import HomePageStore from './HomePageStore';
import AuthStore from './AuthStore';
*/

configure({ enforceActions: 'observed' });



export class RootStore implements RootStoreInterface {
    apiClient = undefined;

    usersStore = undefined;

    modulesStore = {};

    specifications = undefined;

    isStoresInitialized = false;

    /*
    @observable
    partnerStore!: PartnerStore;

    @observable
    legacyRoutesStore!: LegacyRoutesStore;

    @observable
    homePageStore!: HomePageStore;

    @observable
    authStore!: AuthStore;
    */


    constructor() {
        this.init();
    }

    async init(): void {
        this.initApiClient();

        this.modulesStore = {};
        this.initAuthStore();
    }


    initApiClient() {
        const {
                  REACT_APP_API_BASE_URL,
                  REACT_APP_API_CLIENT_ID,
                  REACT_APP_API_CLIENT_SECRET,
              } = process.env;

        if (!REACT_APP_API_BASE_URL) {
            throw new Error('Missing API baseURL');
        }

        /*
        if (!REACT_APP_API_CLIENT_ID) {
          throw new Error('Missing API clientId');
        }

        if (!REACT_APP_API_CLIENT_SECRET) {
          throw new Error('Missing API clientSecret');
        }
        */


        this.apiClient = new WebApi({
            baseURL: REACT_APP_API_BASE_URL,
            clientId: REACT_APP_API_CLIENT_ID,
            clientSecret: REACT_APP_API_CLIENT_SECRET,
        });
    }

    async initAuthStore() {
        this.modulesStore.authStore = new AuthStore(this);
        await this.modulesStore.authStore.checkAuth();
        runInAction( () => {
            this.modulesStore.isAuthStoreInitialized = true;
        })

    }

    async userAuthorized() {
        console.log("AAA0");

        runInAction(async () => {
            //first load specifications
            this.specifications = await this.apiClient.fetchSpecifications();
            this.modulesStore.specificationsStore = new SpecificationsStore(this);

            runInAction(async () => {
                //next load user info
                this.modulesStore.usersStore = new UsersStore(this);
                await this.modulesStore.usersStore.fetchUserInfo();
                console.log("AAA1");

                runInAction(async () => {
                    console.log("AAA2");
                    //then load all other stores.
                    this.modulesStore.webSocketStore = new WebSocketStore(this);
                    this.modulesStore.mailerStore = new MailerStore(this);
                    const initProjectStores = require(`../${process.env.REACT_APP_MODULES_DIR}/stores`).default;
                    console.log("AAA3");
                    initProjectStores(this);
                    this.modulesStore.isStoresInitialized = true;
                    console.log("AAA4");

                });
            });
        });
    }

    addStore(name, store) {
        runInAction(() => {
            this.modulesStore[name] = store;
        });
    }

    resetStores(): void {
        this.init();
    }
}

decorate(RootStore, {
    webSocketStore: observable,
    usersStore: observable,
    modulesStore: observable,
    specifications: observable,
    isStoresInitialized: observable,
    isAuthStoreInitialized: observable,
});

export const createStores = () => {
    const rootStore = new RootStore();
    return rootStore.modulesStore;
};


export const storesContext = React.createContext(createStores());

export const useStores = () => React.useContext(storesContext);

