import React, { Component } from "react";
import GoogleMap from "./GoogleMap";
import AutoComplete from "./AutoCompleteField";
import { isEqual } from "lodash";

const polygonOptions = {
    clickable: false,
    editable: true,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
};

class GoogleMapAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            polygon: props.value ? props.value : [],
            mapPolygon: null,
        };
    }

    componentDidUpdate(_, prevState) {
        // Render polygon on first render and rerender it on each "state.polygon" update
        if (
            this.state.polygon &&
            ((!prevState.mapApiLoaded && this.state.mapApiLoaded) || !isEqual(this.state.polygon, prevState.polygon))
        ) {
            this.renderPolygon(
                this.state.mapInstance,
                this.state.polygon,
                !prevState.mapApiLoaded && this.state.mapApiLoaded,
            );
        }
    }

    clearPolygon = () => {
        this.setState({ polygon: [] });
        if (this.state.mapPolygon) {
            this.state.mapPolygon.setMap(null);
        }
    };

    CenterControl = (controlDiv, map) => {
        // Set CSS for the control border.
        const controlUI = document.createElement("div");
        controlUI.style.backgroundColor = "#fff";
        controlUI.style.border = "2px solid #fff";
        controlUI.style.borderRadius = "3px";
        controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
        controlUI.style.cursor = "pointer";
        controlUI.style.marginTop = "8px";
        controlUI.style.marginBottom = "22px";
        controlUI.style.textAlign = "center";
        controlUI.title = "Очистить точки";
        controlDiv.appendChild(controlUI);

        // Set CSS for the control interior.
        const controlText = document.createElement("div");
        controlText.style.color = "rgb(25,25,25)";
        controlText.style.fontFamily = "Roboto,Arial,sans-serif";
        controlText.style.fontSize = "16px";
        controlText.style.lineHeight = "38px";
        controlText.style.paddingLeft = "5px";
        controlText.style.paddingRight = "5px";
        controlText.innerHTML = "Очистить точки";
        controlUI.appendChild(controlText);

        controlUI.addEventListener("click", () => {
            this.clearPolygon();
        });
    };

    apiHasLoaded = ({ map, maps }) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        if (this.props.onChange) {
            const centerControlDiv = document.createElement("div");
            this.CenterControl(centerControlDiv, map);

            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

            map.addListener("click", (mapsMouseEvent) => {
                mapsMouseEvent.stop();
                let newPolygon = [];
                if (!this.state.polygon || !Array.isArray(this.state.polygon) || !this.state.polygon.length)
                    newPolygon = [[mapsMouseEvent.latLng.toJSON().lng, mapsMouseEvent.latLng.toJSON().lat]];
                else
                    newPolygon = [
                        ...this.state.polygon,
                        [mapsMouseEvent.latLng.toJSON().lng, mapsMouseEvent.latLng.toJSON().lat],
                    ];
                console.log("NEW", JSON.stringify(newPolygon));
                this.setState({ polygon: newPolygon });
            });
        }
    };

    renderPolygon = (map, newPolygon, fitBounds = false) => {
        if (!newPolygon || !Array.isArray(newPolygon) || !newPolygon.length) return;

        if (this.props.onChange) this.props.onChange(newPolygon);

        if (this.state.mapPolygon) {
            this.state.mapPolygon.setMap(null);
        }

        // Define the LatLng coordinates for the polygon's path.

        const path = [];
        newPolygon.forEach((latlng) => {
            path.push({ lat: latlng[1], lng: latlng[0] });
        });
        // path.push({ lat: newPolygon[0][1], lng: newPolygon[0][0] });

        console.log("PATH", JSON.stringify(newPolygon), JSON.stringify(path), { newPolygon });

        // Construct the polygon.
        const bermudaTriangle = new window.google.maps.Polygon({
            ...polygonOptions,
            paths: path,
        });
        bermudaTriangle.setMap(map);
        if (fitBounds) {
            const bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < bermudaTriangle.getPath().length; i++) {
                let point = new window.google.maps.LatLng(path[i].lat, path[i].lng);
                bounds.extend(point);
            }
            map.fitBounds(bounds, -100);
        }
        this.setState({ mapPolygon: bermudaTriangle });

        // Update polygon path if any point or midpoint is moved. The "set_at" event fires when path point is moved. The "insert_at" event fires when midpoint is moved
        window.google.maps.event.addListener(bermudaTriangle.getPath(), "set_at", this.handlePointDrag.bind(this));
        window.google.maps.event.addListener(bermudaTriangle.getPath(), "insert_at", this.handlePointDrag.bind(this));
    };

    handlePointDrag(index, latLng) {
        const pathArray = this.state.mapPolygon
            .getPath()
            .getArray()
            .map((latLng) => [latLng.lng(), latLng.lat()]);
        this.setState({ polygon: pathArray });
    }

    render() {
        const { places, mapApiLoaded, mapInstance, mapApi } = this.state;
        return (
            <>
                {mapApiLoaded && this.props.onChange ? (
                    <AutoComplete map={mapInstance} mapApi={mapApi} {...this.props} />
                ) : null}
                <div style={this.props.showMap ? { height: "500px", width: "100%" } : { height: 0, width: 0 }}>
                    <GoogleMap
                        defaultZoom={13}
                        defaultCenter={this.props.location}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_MAP_KEY,
                            libraries: ["places", "geometry"],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={this.apiHasLoaded}
                    ></GoogleMap>
                </div>
            </>
        );
    }
}

export default GoogleMapAutoComplete;
