import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Email from "./routes/Email/route";
import Username from "./routes/Username/route";
import Phone from "./routes/Phone/route";

//import Phone from "./routes/phone/route";
//import Login from "./routes/login/route";
/*
import SignInByLogin from "./routes/SignInByLogin";
import SignIn from "./routes/SignInByEmail";
import SignInByPhone from "./routes/SignInByPhone";

import Verify from "./routes/Verify";
import SignUp from "./routes/SignUp";
import ForgotPassword from "./routes/ForgotPassword";

import VkAuth from "./routes/VkAuth";
*/

const Routes = ({ match }) => {
    return (
        <Switch>
            {/*
            <Route path={`${match.url}/signup`} component={SignUp}/>
            <Route path={`${match.url}/vk-auth`} component={VkAuth}/>
            <Route path={`${match.url}/forgot`} component={ForgotPassword}/>
            <Route path={`${match.url}/verify/:uuid`} component={Verify}/>
            <Route path={`${match.url}/verify`} component={Verify}/>

            <Route path={`${match.url}/phone`} component={SignInByPhone}/>
            <Route path={`${match.url}/email`} component={SignIn}/>
            <Route path={`${match.url}/phone`} component={SignInByLogin}/>
*/}
            <Route path={`${match.url}/email`} component={Email} />
            <Route path={`${match.url}/username`} component={Username} />
            <Route path={`${match.url}/phone`} component={Phone} />

            {process.env.REACT_APP_PROJECT === "healthylab" ? (
                <Route path={`${match.url}/`} component={Username} />
            ) : (
                <Route path={`${match.url}/`} component={Email} />
            )}
        </Switch>
    );
};

export default withRouter(Routes);
