import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Auth from "./Auth/route";
import Users from "./Users/route";
import Mailer from "./Mailer/route";
import Page404 from "components/Errors/NotFound";
import RestrictedRoute from "util/restrictedRoute";

const Routes = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}auth`} component={Auth} />
            <RestrictedRoute path={`${match.url}users`} component={Users} />
            <RestrictedRoute path={`${match.url}mailer`} component={Mailer} />

            <Route component={Page404} />
        </Switch>
    );
};

export default withRouter(Routes);
