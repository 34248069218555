import React, { useEffect, useState } from "react";
import { reaction } from "mobx";
//import { reaction } from 'mobx-react';
import { useStores } from "modules/stores";
import { useHistory } from "react-router-dom";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { NotificationContainer, NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "components/Errors/Error";
import LogoVertical from "assets/components/LogoVertical";

/*
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn
} from 'actions/Auth';
*/

import EditField from "components/table/EditField";

import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import VK, { Auth } from "react-vk";
import VkAuth from "react-vk-auth";

const SignIn = (props) => {
    const { authStore } = useStores();

    /*
  const loginStore = useLocalStore (() => ({
    schema: "signUp",
    login: "",
    password: ""
  }))
  */

    const history = useHistory();

    // hanlde successfull login and logout
    useEffect(() => {
        if (props.location.search.match(/token=/g)) {
            const token = props.location.search.replace(/.*token=/g, "");
            authStore.setAuthorizationToken(token);
        }
        reaction(
            () => authStore.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) {
                    history.push("/");
                }
            },
        );
    }, []);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        authStore.userSignIn({ username, password, code: props.match.params.code });
    };
    const handleKeyUp = (ev) => {
        if (ev.keyCode == 13) {
            handleLogin();
        }
    };

    return useObserver(() => {
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <LogoVertical />

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>
                                <IntlMessages id="Auth.signInTitle" />
                            </h1>
                        </div>

                        {authStore.loginError && (
                            <Error>
                                <IntlMessages
                                    id={`Auth.${authStore.loginError}`}
                                    defaultMessage={authStore.loginError}
                                />
                            </Error>
                        )}

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <EditField
                                        field={{ name: "username", type: "string" }}
                                        onChange={(field, value, event) => setUsername(value)}
                                        onError={""}
                                        store={{ specification: { moduleName: "Auth" } }}
                                        value={username}
                                        variant="standard"
                                        onEnter={handleLogin}
                                    />
                                    <EditField
                                        field={{ name: "password", type: "password" }}
                                        onChange={(field, value, event) => setPassword(value)}
                                        store={{ specification: { moduleName: "Auth" } }}
                                        value={password}
                                        variant="standard"
                                        onEnter={handleLogin}
                                    />

                                    <div
                                        className="mb-3 d-flex align-items-center justify-content-between"
                                        style={{ paddingTop: 20 }}
                                    >
                                        <Button onClick={handleLogin} variant="contained" color="primary">
                                            <IntlMessages id="Auth.signIn" />
                                        </Button>

                                        {process.env.REACT_APP_PROJECT !== "taxi" ? (
                                            <Link
                                                to={`/auth/username/signup${
                                                    props.match.params.code
                                                        ? `${props.match.params.code ? `/${props.match.params.code}` : ""}`
                                                        : ""
                                                }`}
                                            >
                                                <IntlMessages id="Auth.signUp" />
                                            </Link>
                                        ) : null}
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                {authStore.isLoginInProgress && (
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    });
};
//isLoginInProcess
//dispatch(showAuthLoader());

export default SignIn;
