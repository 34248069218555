import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import SignIn from "./SignIn";
//import SignUp from "./SignUp";

const Routes = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/signin/:code`} component={SignIn} />
            <Route path={`${match.url}/signin`} component={SignIn} />
            <Route path={`${match.url}/`} component={SignIn} />
        </Switch>
    );
};

export default withRouter(Routes);
