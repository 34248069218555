import React, { Component } from "react";
import isEmpty from "lodash.isempty";
import Marker from "./Marker";
import GoogleMap from "./GoogleMap";
import AutoComplete from "./AutoCompleteField";

// consts
//const LOS_ANGELES_CENTER =  [55.75, 37.6167]

class GoogleMapAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: props.value ? [props.value] : [],
        };
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
        if (this.props.showMap) {
            map.addListener("idle", () => this.onIdle(map));
        }
    };

    addPlace = (place) => {
        this.setState({ places: [place] });
        if (this.props.onChange) this.props.onChange(place);
    };

    fetchAddressFromCoords = async (location, language) => {
        let latlng = location.lat + "," + location.lng;
        console.log("LANG", language);
        console.log(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                latlng +
                "&key=" +
                process.env.REACT_APP_MAP_KEY +
                "&language=" +
                language,
        );
        const response = await fetch(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                latlng +
                "&key=" +
                process.env.REACT_APP_MAP_KEY +
                "&language=" +
                language,
        );
        const json = await response.json();

        if (json.results && json.results.length > 0 && json.results[0]) {
            return json.results[0];
        }
        return null;
    };
    getGoogleLocationPlace = (location) => {
        let address = "";
        const detail = {};
        location.address_components.reverse().forEach((item) => {
            if (
                //item.types.includes("country") ||
                //item.types.includes("administrative_area_level_1") ||
                //item.types.includes("administrative_area_level_2") ||
                item.types.includes("locality") ||
                item.types.includes("route") ||
                item.types.includes("establishment") ||
                item.types.includes("street_number")
            ) {
                if (address !== "") address += ", ";
                address += item.long_name;
            }

            detail[item.types[0]] = item.long_name;
        });

        const place = {
            address: location.name || location.formatted_address,
            detail,
            lat: location.geometry.location.lat,
            lng: location.geometry.location.lng,
        };
        return place;
    };

    onIdle = async (map) => {
        try {
            const place = await this.fetchAddressFromCoords(map.getCenter().toJSON(), "ru");
            this.addPlace({ ...place, ...map.getCenter().toJSON() });
        } catch (e) {}
    };

    render() {
        const { places, mapApiLoaded, mapInstance, mapApi } = this.state;

        return (
            <>
                {mapApiLoaded ? (
                    <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} {...this.props} />
                ) : null}
                <div style={this.props.showMap ? { height: "300px", width: "100%" } : { height: 0, width: 0 }}>
                    <GoogleMap
                        defaultZoom={13}
                        defaultCenter={this.props.location}
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_MAP_KEY,
                            libraries: ["places", "geometry"],
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    >
                        {!isEmpty(places) &&
                            places.map((place) => {
                                if (place.geometry) {
                                    return (
                                        <Marker
                                            key={place.id}
                                            text={place.name}
                                            lat={
                                                typeof place.geometry.location.lat === "number"
                                                    ? place.geometry.location.lat
                                                    : place.geometry.location.lat()
                                            }
                                            lng={
                                                typeof place.geometry.location.lng === "number"
                                                    ? place.geometry.location.lng
                                                    : place.geometry.location.lng()
                                            }
                                        />
                                    );
                                }
                            })}
                    </GoogleMap>
                </div>
            </>
        );
    }
}

export default GoogleMapAutoComplete;
