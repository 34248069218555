import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useStores } from "modules/stores";
import { useObserver } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import DetailPage from "components/table/DetailPage";
import Header from "./header";
import { withRouter } from "react-router";
import menu from "./menu";

const Detail = ({ match }) => {
    const history = useHistory();

    const { mailerStore: store } = useStores();

    const { mailer_id } = match.params;

    useEffect(() => {
        if (!store.record || store.record.id != mailer_id) store.fetchRecord(mailer_id, true);
    }, []);

    const handleChangeRecord = (id) => {
        history.push(`/app/mailer/${id}`);
    };

    return useObserver(() => {
        return (
            <DetailPage store={store} handleChangeRecord={handleChangeRecord}>
                <Header store={store} />
                <div className="jr-profile-content">
                    <Switch>
                        {menu.map((item, index) => {
                            return (
                                <Route
                                    path={`${match.url}${item.route}`}
                                    render={(props) => <item.component filter={{ mailer_id }} {...props} />}
                                    exact
                                />
                            );
                        })}
                    </Switch>
                </div>
            </DetailPage>
        );
    });
};

export default withRouter(Detail);
