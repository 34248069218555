import React, { useEffect } from 'react';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { useStores } from 'modules/stores';
import { useLocation, Redirect } from 'react-router-dom';
import SidebarList from "components/table/SidebarList";
import Auxiliary from "util/Auxiliary";
import CircularProgress from "components/CircularProgress";
import Error from "components/Errors/Error";

const DetailPage: React.FC = ({ store, filter, children, handleChangeRecord, style, showSidebar = true }) => {
    const {usersStore} = useStores();
    let prevRecordId = 0;

    return useObserver(() => {
        console.log("FFFFFFFFFFFFFFF", JSON.stringify(usersStore.me));
        return (
            <div className="app-wrapper app-wrapper-module">
                <div className="app-module chat-module">
                    <div className="chat-module-box">
                        {showSidebar && store.list !== undefined && store.fetchingListError !== 'Forbidden' && (
                            <div className="chat-sidenav d-none d-xl-flex">
                                <SidebarList
                                    filter={filter}
                                    store={store}
                                    onSelect={handleChangeRecord}
                                />
                            </div>)}
                        <div className="chat-box">
                            {store.isFetchingRecordInProgress &&
                                <CircularProgress/>
                            }
                            {store.fetchingRecordError &&
                                <Error>{store.fetchingRecordError}</Error>
                            }
                            {!store.isFetchingRecordInProgress && !store.fetchingRecordError && store.record &&

                                <div className={`chat-box-main ${0 ? 'animated slideInUpTiny animation-duration-3' : ''}`}
                                     style={{justifyContent: "inherit", margin: "30px", ...style}}>
                                    <Auxiliary>
                                        {children}
                                    </Auxiliary>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
        /*
//        if ( authStore.isClientAccessTokenFetched ) {
            alert ( authStore.isAuthenticated  + ":" + location.pathname );

            if (!authStore.isAuthenticated && location.pathname !== '/signin') {

                return <Redirect to={'/signin'}/>
            } else if (authStore.isAuthenticated && location.pathname === '/') {
                    return ( <Redirect to={'/app/dashboard/eCommerce'}/> );
                //} else {
                //    return ( <Redirect to={initURL}/> );
               // }
            }
//        }
*/

   //     return children;
    });
};

export default DetailPage;
