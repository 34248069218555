import { AxiosInstance } from "axios";
import CommonCRUD from "modules/CommonCRUD";

class OrdersCosts extends CommonCRUD {
    async change(orderId, info) {
        const { id, ...rest } = info;
        return await this.api.fetch({
            method: "post",
            url: `${this.schema}/change`,
            data: { order_id: orderId, ...rest },
        });
    }
}

export default OrdersCosts;
