import React from "react";
import Vertical from "./Vertical/index";
import InsideHeaderNav from "./Horizontal/InsideHeaderNav";
import BelowHeaderNav from "./Horizontal/BelowHeaderNav";
import AboveHeaderNav from "./Horizontal/AboveHeaderNav";

const Empty = (props) => {
    return <>{props.children}</>;
};

const AppLayouts = {
    Vertical,
    InsideHeaderNav,
    BelowHeaderNav,
    AboveHeaderNav,
    Empty,
};
export default AppLayouts;
