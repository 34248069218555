import {
    observable,
    action,
    runInAction,
    reaction,
    decorate
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import CommonStore from "modules/CommonStore";

class SpecificationsStore extends CommonStore {

    isModified = 0;


    constructor(rootStore) {
        super(rootStore);
        this.specification = {
            "schema": "specification",
            "fields": [

                {
                    "name": "name",
                    "type": "string",
                    "max": 128,
                    "required": true
                },
                {
                    "name": "type",
                    "type": "dropdown",
                    "link":{
                        "list": [
                            {"id":"string", "name":"String"},
                            /*
                            {"id":"text", "name":"Text"},
                            {"id":"avatar", "name":"Avatar"},
                            {"id":"file", "name":"File"},
                             */
                            {"id":"number", "name":"Number"},
                            {"id":"date", "name":"Date"},
                            {"id":"question", "name":"Question"},
                            {"id":"datetime", "name":"Datetime"}
                        ]
                    },
                    "required": true
                },
                /*

                {
                    "name": "max",
                    "type": "number",
                    "required": false
                },
                 */

/*
                {
                    "name": "formType",
                    "type": "string",
                    "formType": "dropdown",
                    "link":{
                        "list": [
                            {"id":"", "name":"Input"},
                            {"id":"radio", "name":"Radio"},
                            {"id":"select", "name":"Select"},
                            {"id":"textarea", "name":"TextArea"}
                        ]
                    },
                    "required": false
                },
*/
/*
                {
                    "name": "formRows",
                    "type": "number",
                    "required": false,
                    "formCondition": "$type === 'text' || $formType === 'textarea'"
                },
*/

                {
                    "name": "required",
                    "type": "number",
                    "max": 1,
                    "formType": "radio",
                    "link":{
                        "list": [
                            {"id":"0", "name":"Yes"},
                            {"id":"1", "name":"No"}
                        ]
                    },
                    "required": false
                },
/*
                {
                    "name": "list",
                    "type": "string",
                    "required": false
                },
                {
                    "name": "link",
                    "type": "string",
                    "required": false
                },
                {
                    "name": "icon",
                    "type": "string",
                    "required": false
                }
                */

            ],
            "columns": {
                "add": [
                    "name",
                    "type",
                    "formType",
                    "formRows",
                    "required",
                    "list",
                    "link",
                    "icon",
                    "max"
                ],
                "list": [
                    "name",
                    "type"
                ]
            }

        };
        //rootStore.specifications["specification"];
    }

    async fetchList() { }


    async loadList(list): Promise<void> {
        this.list = [];
        if ( list )
            list.forEach(item => this.dispatch(item, "add"))
    }

    async addRecord(params, isShift = true): Promise<void> {
        runInAction(() => {
            this.isModified++;
        });
        this.dispatch({id: uuidv4(), ...params}, "new", isShift);
    }


    async updateRecord(id, record): Promise<void> {
        runInAction(() => {
            this.isModified++;
        });
        this.dispatch({id, ...record}, "update");
    }


    async deleteRecord(id, params): Promise<void> {
        runInAction(() => {
            this.isModified++;
        });
        this.dispatch({id}, "delete");
    }

    async setModuleName(name) {
        runInAction(() => {
            this.specification.moduleName = name;
        });
    }
}


decorate(SpecificationsStore, {
    fetchList: action,
    loadList: action,

    isModified: observable,

    addRecord: action,
    updateRecord: action,
    deleteRecord: action,
});


export default SpecificationsStore;
