import React from "react";
import { useStores } from "modules/stores";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";

const DetailNenuNav = ({ menu, match, type }) => {
    const history = useHistory();
    const { usersStore } = useStores();
    const role = usersStore.role;

    const handleChange = (e) => {
        console.log(e);
    };

    let active = 0;
    const selected = menu.reduce((prevValue, item, index) => {
        const regexp1 = new RegExp(match.url, "g");
        const regexp2 = new RegExp(item.match, "g");
        if (prevValue === null && window.location.pathname.replace(regexp1, "").match(regexp2)) {
            return index;
        }
        return prevValue;
    }, null);

    return (
        <div style={{ width: "100%" }}>
            {type === "bi1g" ? (
                <div sty2le={{ padding: "0px 300px", position: "absolute", top: 0, zIndex: 99999 }}>
                    {/*
                .jr-profile-banner {
                    margin:-30px;
                    padding:30px 0px 0px 0px;

                }
                */}

                    <AppBar className="bg-primary" position="static">
                        <Tabs variant="fullWidth" value={selected} onChange={handleChange} scrollButtons="on">
                            {menu.map((item, index) => {
                                if (item.roles && !(role === "admin" || item.roles.includes(role))) return;

                                if (item.hidden) return;
                                const regexp1 = new RegExp(match.url, "g");
                                const regexp2 = new RegExp(item.match, "g");
                                const active = window.location.pathname.replace(regexp1, "").match(regexp2)
                                    ? "active"
                                    : "";
                                let link;
                                link = item.component ? `${match.url}${item.route}` : item.route;
                                link = link.replace(/\/\//g, "/");

                                return (
                                    <Tab
                                        className="tab"
                                        label={item.title}
                                        value={index}
                                        onClick={() => {
                                            history.push(link);
                                        }}
                                        icon={<i className={`zmdi zmdi-${item.icon}`} />}
                                    />
                                );
                            })}
                        </Tabs>
                    </AppBar>
                </div>
            ) : (
                <div className="jr-tab-list">
                    <ul className="jr-navbar-nav">
                        {menu.map((item, index) => {
                            if (item.roles && !(role === "admin" || item.roles.includes(role))) return;

                            if (item.hidden) return;
                            const regexp1 = new RegExp(match.url, "g");
                            const regexp2 = new RegExp(item.match, "g");
                            const active = window.location.pathname.replace(regexp1, "").match(regexp2) ? "active" : "";
                            let link;
                            link = item.component ? `${match.url}${item.route}` : item.route;
                            link = link.replace(/\/\//g, "/");

                            return (
                                <li key={index}>
                                    <span className="jr-link">
                                        <Link to={link} className={active}>
                                            {item.title}
                                        </Link>
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default withRouter(DetailNenuNav);
