import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import configureStore, { history } from "./store-redux";
import App from "./modules_taxi/App/App";

export const store = configureStore();

const MainApp = () => {
    //    const App = require(`./${process.env.REACT_APP_MODULES_DIR}/App/App`);

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
};

export default MainApp;
