import React from "react";
import Avatar from "components/table/Avatar";
import moment from "moment";

const SidebarListCell = ({ item, store, onSelect }) => {
    return (
        <div
            key={item.id}
            className={`chat-user-item  ${store.record && store.record.id === item.id ? "active " : " "} ${
                item.ctime && (Date.now() / 1000 - item.ctime) / (60 * 60 * 24) < 1 ? " chat-user-new-record " : ""
            }`}
            onClick={() => {
                onSelect(item);
            }}
        >
            <div className="chat-user-row row">
                <div className="chat-avatar col-xl-2 col-3">
                    <Avatar size={40} record={item} />
                </div>

                <div className="chat-info col-xl-8 col-6">
                    <span className="name h4">
                        {item.name && (item.name.length <= 20 ? item.name : item.name.substring(0, 20) + "...")}
                    </span>
                    <div className="chat-info-des">
                        {item.description &&
                            (item.description.length <= 25
                                ? item.description
                                : item.description.substring(0, 25) + "...")}
                    </div>
                </div>
                {item.unread_count && item.unread_count > 0 ? (
                    <div className="chat-date col-xl-2 col-3">
                        <div className="bg-primary rounded-circle badge text-white">{item.unread_count}</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default SidebarListCell;
