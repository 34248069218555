import React from "react";
import { useStores } from "modules/stores";
import DataTable from "components/table/DataTable";

const MailerList = ({ match, filter }) => {
    const { mailerStore } = useStores();

    return (
        <div className="app-wrapper">
            <DataTable detailUrl={match.path} filter={filter} store={mailerStore} />
        </div>
    );
};

export default MailerList;
