import { applyMiddleware, compose, createStore } from "redux";
//import reducers from '../reducers/index';
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
//import rootSaga from '../sagas/index';
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
//import Settings from './Settings';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//import Auth from './Auth';

export default function configureStore(initialState) {
    const store = createStore(
        combineReducers({
            router: connectRouter(history),
        }),
        initialState,
    );

    //sagaMiddleware.run(rootSaga);

    /*
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  */
    return store;
}
export { history };
