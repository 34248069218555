import React, { useEffect, useState, useContext } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Redirect, Route, Switch } from "react-router-dom";
//import {useDispatch, useSelector} from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import taxiTheme from "./themes/taxiTheme";
import zakautTheme from "./themes/zakautTheme";
import healthylabTheme from "./themes/healthylabTheme";
import AppLocale from "lngProvider";
import { useObserver } from "mobx-react-lite";
import { useStores } from "modules/stores";
import CircularProgress from "components/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import queryString from "query-string";
import NotFoundComponent from "components/Errors/NotFound";
import ModulesRoute from "modules/route";
/*
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";
*/
//import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import asyncComponent from "util/asyncComponent";
//import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";
//import { WssContext } from "../services/wssContext";
import { observable, action, runInAction, reaction, decorate } from "mobx";
import { NotificationContainer } from "react-notifications";

import RestrictedRoute from "util/restrictedRoute";
//import ZakautCustomerLayout from "../CustomersArea/route";

const App = (props) => {
    //const dispatch                 = useDispatch();
    //const {locale, isDirectionRTL} = useSelector(({settings}) => settings);
    //const {initURL}                = useSelector(({auth}) => auth);
    const { match } = props;
    const [redirectCalled, setRedirectCalled] = useState(false);
    const history = useHistory();

    const stores = useStores();

    //const context = useContext(WssContext);

    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        /*
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      //dispatch(setThemeColor(params.get('theme-name')));
    }
    */
    }, [props.history.location.pathname, props.location.search]);

    useEffect(() => {
        let urlParams = queryString.parse(props.location.search);
        if (urlParams["utm_source"]) {
            localStorage.setItem("utm_source", urlParams["utm_source"]);
        }
        if (urlParams["utm_campaign"]) {
            localStorage.setItem("utm_campaign", urlParams["utm_campaign"]);
        }
        if (urlParams["utm_term"]) {
            localStorage.setItem("utm_term", urlParams["utm_term"]);
        }
        if (urlParams["utm_content"]) {
            localStorage.setItem("utm_content", urlParams["utm_content"]);
        }
    }, []);

    //const applyTheme = createMuiTheme(indigoTheme)
    let applyTheme;
    if (process.env.REACT_APP_PROJECT === "zakaut") {
        applyTheme = createMuiTheme(zakautTheme);
    } else if (process.env.REACT_APP_PROJECT === "taxi") {
        applyTheme = createMuiTheme(taxiTheme);
    } else {
        applyTheme = createMuiTheme(healthylabTheme);
    }
    /*
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }
  */

    const { usersStore, authStore } = stores;

    return useObserver(() => {
        if (
            !stores.isAuthStoreInitialized ||
            (stores.isAuthStoreInitialized && authStore.isAuthenticated && !stores.isStoresInitialized)
        ) {
            console.log("Waiting auth store initialisation");
            return <CircularProgress />;
        }

        if (!document.location.pathname.match(/^\/auth\//) && !authStore.isAuthenticated) {
            history.push("/auth/signin");
            return <CircularProgress />;
        }

        if (
            document.location.pathname === "/" ||
            document.location.pathname === "/app" ||
            document.location.pathname === "/app/" ||
            document.location.pathname.match(/^\/auth\//)
        ) {
            if (authStore.isAuthenticated && usersStore && usersStore.me && usersStore.homePage) {
                /* bug of the react router when we click browser back button multiple times  */
                setTimeout(() => {
                    history.push(usersStore.homePage);
                }, 100);
                return <CircularProgress />;
            }
        }

        if (authStore.isAuthenticated && (!usersStore || !usersStore.isUserInfoFetched)) {
            return <CircularProgress />;
        }

        if (
            usersStore &&
            usersStore.me &&
            usersStore.me.role &&
            usersStore.me.role.name !== "admin" &&
            usersStore.me.role.name !== "manager"
        ) {
            document.location = "/customer/";
        }

        let currentAppLocale = AppLocale[process.env.REACT_APP_LOCALE];
        if (usersStore && usersStore.me && usersStore.me.lang && usersStore.me.lang.locale) {
            currentAppLocale = AppLocale[usersStore.me.lang.locale];
        }

        //
        //alert(JSON.stringify(applyTheme));

        return (
            <ThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                        <RTL>
                            <div className="app-main">
                                <Switch>
                                    <RestrictedRoute
                                        path={`${match.url}app`}
                                        isAuthenticated={authStore.isAuthenticated}
                                        component={AppLayout}
                                    />
                                    {/*
                  <RestrictedRoute path={`${match.url}customer`} isAuthenticated={authStore.isAuthenticated}
                                   component={ZakautCustomerLayout}/>
                                   */}
                                    <Route path={`${match.url}`} component={ModulesRoute} />
                                    <Route component={NotFoundComponent} />
                                </Switch>
                                <NotificationContainer />
                            </div>
                        </RTL>
                    </IntlProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        );
    });
};

export default App;
