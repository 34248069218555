export default [
    {
        name: "sidebar.main",
        type: "section",
        children: [
            {
                name: "sidebar.Analytics",
                icon: "trending-up",
                type: "item",
                link: "/app/analytics",
            },

            {
                name: "sidebar.customers",
                icon: "grain",
                type: "section",
                children: [
                    {
                        name: "sidebar.customers",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/customers",
                    },
                    {
                        name: "sidebar.employees",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/employees",
                    },
                    {
                        name: "sidebar.customersReports",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/customers-reports",
                    },
                ],
            },

            {
                name: "sidebar.orders",
                icon: "grain",
                type: "section",
                children: [
                    {
                        name: "sidebar.orders",
                        icon: "border-all",
                        type: "item",
                        link: "/app/orders",
                    },
                    {
                        name: "sidebar.reports",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/reports",
                    },
                ],
            },

            {
                name: "sidebar.providers",
                icon: "grain",
                type: "section",
                children: [
                    {
                        name: "sidebar.providers",
                        icon: "car-taxi",
                        type: "item",
                        link: "/app/providers",
                    },
                    {
                        name: "sidebar.providersPriority",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/providers-priority",
                    },

                    {
                        name: "sidebar.cities",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/cities",
                    },
                    {
                        name: "sidebar.cities_groups",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/cities-groups",
                    },
                    {
                        name: "sidebar.providers_groups",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/providers-groups",
                    },

                    {
                        name: "sidebar.cities_airports",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/cities-airports",
                    },
                ],
            },

            {
                name: "sidebar.tariffs",
                icon: "collection-item-8",
                type: "section",
                children: [
                    {
                        name: "sidebar.tariffs",
                        icon: "collection-item-8",
                        type: "item",
                        link: "/app/tariffs",
                    },
                ],
            },

            {
                name: "sidebar.settings",
                icon: "collection-item-8",
                type: "section",
                children: [
                    {
                        name: "sidebar.managers",
                        icon: "account-o",
                        type: "item",
                        link: "/app/managers",
                    },
                    {
                        name: "sidebar.operators",
                        icon: "account-o",
                        type: "item",
                        link: "/app/operators",
                    },
                    {
                        name: "sidebar.admins",
                        icon: "account-o",
                        type: "item",
                        link: "/app/admins",
                    },
                    {
                        name: "sidebar.users",
                        icon: "account-o",
                        type: "item",
                        link: "/app/users",
                    },
                    {
                        name: "sidebar.access_tokens",
                        icon: "account-o",
                        type: "item",
                        link: "/app/access-tokens",
                    },
                ],
            },

            {
                name: "sidebar.help",
                icon: "border-all",
                type: "section",
                children: [
                    {
                        name: "sidebar.help_requests",
                        icon: "border-all",
                        type: "item",
                        link: "/app/help-requests",
                    },
                ],
            },

            /*,
      {
        name: 'sidebar.users',
        icon: 'accounts-outline',
        type: 'item',
        link: '/app/users'
      }
      */
        ],
    },
];
