import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
//import { useDispatch, useSelector } from "react-redux";

import Drawer from "@material-ui/core/Drawer";
//import UserInfo from "components/UserInfo";
//import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION,VERTICAL_NAVIGATION } from "constants/ActionTypes";

//import { toggleCollapsedNav, updateWindowWidth } from "actions/Setting";
import SideBarContent from "./SideBarContent";
import { useStores } from "../../modules/stores";
import LogoHorizontal from "assets/components/LogoHorizontal";

const FIXED_DRAWER = "fixed_drawer";
const COLLAPSED_DRAWER = "collapsible";
const HORIZONTAL_NAVIGATION = "horizontal_navigation";
const VERTICAL_NAVIGATION = "vertical_navigation";

const SideBar = () => {
    const { usersStore } = useStores();
    /*
    const dispatch = useDispatch();

    const {
        navCollapsed, drawerType, width,
    } = useSelector(({ settings }) => settings);
    */
    const navCollapsed = false;
    const drawerType = FIXED_DRAWER;
    const width = window.innerWidth;

    const navigationStyle = usersStore.role !== "admin" ? HORIZONTAL_NAVIGATION : VERTICAL_NAVIGATION;

    useEffect(() => {
        window.addEventListener("resize", () => {
            //    dispatch(updateWindowWidth(window.innerWidth));
        });
    }, []);

    const onToggleCollapsedNav = (e) => {
        //dispatch(toggleCollapsedNav());
    };

    let drawerStyle = drawerType.includes(FIXED_DRAWER)
        ? "d-xl-flex"
        : drawerType.includes(COLLAPSED_DRAWER)
          ? ""
          : "d-flex";
    let type = "permanent";
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
        type = "temporary";
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
        drawerStyle = "";
        type = "temporary";
    }

    //console.log("navCollapsed in drawer file", type, navCollapsed);
    return (
        <div className={`app-sidebar d-none ${drawerStyle}`}>
            <Drawer
                className="app-sidebar-content"
                variant={type}
                open={type.includes("temporary") ? navCollapsed : true}
                onClose={onToggleCollapsedNav}
                classes={{
                    paper: "side-nav",
                }}
            >
                <Link className="app-sidebar-logo mr-2 d-none d-sm-block" to={usersStore.homePage}>
                    <LogoHorizontal color={"#fff"} />
                </Link>

                <SideBarContent />
            </Drawer>
        </div>
    );
};

export default withRouter(SideBar);
