import { AxiosInstance } from 'axios';

class CommonCRUD {
    api: AxiosInstance;
    schema = undefined;

    constructor(api) {
        this.api = api;
    }

    setSchemaUrl(schema) {
        this.schema = schema;
    }


    async list(filter, orderBy, page, limit, detail) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/list`,
            data: {
                filter,//:JSON.stringify(filter),
                orderBy,
                page,
                limit,
                detail: detail ? 1 : 0
            }
        });
    }

    async export(moduleName, filter, orderBy, fields, fileType) {
        return await this.api.fetch({
            method: 'post',
            url: '/reports',
            data: {
                moduleName,
                filter,
                orderBy,
                fields,
                fileType
            },
            returnBody: true
        }, true);
    }

    async checkExport(id) {
        return await this.api.fetch({
            method: 'get',
            url: `/reports/${id}`,
        })
    }

    async checkImport(id) {
        return await this.api.fetch({
            method: 'get',
            url: `/orders/import/${id}`,
        })
    }
    /*
        async import(filter, orderBy, fileType) {
            return await this.api.fetch({
                method:'post',
                url:`/${this.schema}/import`,
                data:{
                    filter,
                    orderBy,
                    fileType
                },
                returnBody: true
            });
        }
        */

    async importSample(fileType) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/import-sample`,
            data: { fileType },
            returnBody: true
        });
    }

    async linkedList(fieldName, filter, limit) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}/linked-list/${fieldName}`,
            data: {
                filter,
                ...limit ? { limit } : {}
            }

        });
    }

    async get(id, detail) {
        return await this.api.fetch({
            method: 'get',
            url: `/${this.schema}/${id}?detail=${detail ? 1 : 0}`
        });
    }

    async add(data) {
        return await this.api.fetch({
            method: 'post',
            url: `/${this.schema}`,
            data
        });
    }

    async update(id, data) {
        return await this.api.fetch({
            method: 'put',
            url: `/${this.schema}/${id}`,
            data
        });
    }

    async delete(id) {
        return await this.api.fetch({
            method: 'delete',
            url: `/${this.schema}/${id}`
        });
    }


    /*
    async subscribeWS(filter) {
        return await this.api.fetch({
            method:'get',
            url:`/${this.schema}/subscribeWS?filter=${JSON.stringify(filter)}`
        });
    }
    */

}

export default CommonCRUD;
