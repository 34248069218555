import enLang from "./entries/en-US";
import ruLang from "./entries/ru-RU";

//TODO. remove it, fix it, or smthg like this
const AppLocale = {
    en: enLang,
    ru: ruLang,
    en_US: enLang,
    ru_RU: ruLang,
};

export default AppLocale;
