import React from "react";
import { useObserver } from "mobx-react-lite";
import AvatarUpload from "components/table/AvatarUpload";
import DetailMenuNav from "components/table/DetailMenuNav";
import menu from "./menu";

const ProfileHeader = (props) => {
    const { store } = props;

    return useObserver(() => {
        const { record } = store;
        return (
            <div className="jr-profile-banner">
                <div className="jr-profile-container">
                    <div className="jr-profile-banner-top">
                        <div className="jr-profile-banner-top-left">
                            <div className="jr-profile-banner-avatar">
                                <AvatarUpload store={store} />
                            </div>
                            <div className="jr-profile-banner-avatar-info">
                                <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                                    {record.name_short ? record.name_short : record.name}
                                </h2>
                                <p className="mb-0 jr-fs-lg">{record.description}</p>
                            </div>
                        </div>
                        <div className="jr-profile-banner-top-right">
                            <ul className="jr-follower-list">
                                <li>
                                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                                        {record.balance || 0} руб
                                    </span>
                                    <span className="jr-fs-sm">Баланс</span>
                                </li>
                                <li>
                                    <span className="jr-follower-title jr-fs-lg jr-font-weight-medium">
                                        {record.client_count || 0}{" "}
                                    </span>
                                    <span className="jr-fs-sm">Клиентов</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="jr-profile-banner-bottom">
                        <DetailMenuNav menu={menu} />
                    </div>
                </div>
            </div>
        );
    });
};

export default ProfileHeader;
