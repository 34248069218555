import React, { useEffect } from "react";
import { useStores } from "modules/stores";
import { useObserver } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import DetailPage from "components/table/DetailPage";
import Header from "./header";
import Router from "./router";

const Detail = ({ match }) => {
    const history = useHistory();

    const { usersStore: store } = useStores();

    useEffect(() => {
        if (!store.record || store.record.id != match.params.record_id) store.fetchRecord(match.params.record_id, true);
    }, []);

    const handleChangeRecord = (id) => {
        history.push("/app/users/" + id);
    };

    return useObserver(() => {
        return (
            <DetailPage store={store} handleChangeRecord={handleChangeRecord}>
                <Header store={store} />
                <div className="jr-profile-content">
                    <Router store={store} match={match} />
                </div>
            </DetailPage>
        );
    });
};

export default Detail;
