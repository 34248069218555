import { decorate, observable, action, runInAction } from 'mobx';
import CommonStore from "modules/CommonStore";
import EmployeesJournalAPI from "./api"

class EmployeesJournalStore extends CommonStore {

  constructor(rootStore: RootStoreInterface) {
    const apiClient = new EmployeesJournalAPI(rootStore.apiClient);

    super(
        rootStore,
        apiClient,
        rootStore.specifications["EmployeesJournal"]
    );
  }

}

decorate(EmployeesJournalStore, {

});

export default EmployeesJournalStore;
