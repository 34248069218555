import React, { useEffect, useState } from "react";
import { reaction } from "mobx";
// import { reaction } from 'mobx-react';
import { useStores } from "modules/stores";
import { useHistory, Link } from "react-router-dom";
import { useObserver, useLocalStore } from "mobx-react-lite";

import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { NotificationContainer, NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "components/Errors/Error";
import LogoVertical from "assets/components/LogoVertical";

const Verify = (props) => {
    const { authStore } = useStores();
    const history = useHistory();

    if (
        props.match.params.uuid &&
        !authStore.isAuthenticated &&
        !authStore.isVerifyInProgress &&
        !authStore.verifyError
    ) {
        authStore.userVerify(props.match.params.uuid);
    } else if (authStore.isAuthenticated) {
        history.push("/");
    }

    return useObserver(() => (
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">
                <LogoVertical />

                <div className="app-login-content">
                    <div className="app-login-header mb-4">
                        <h1>
                            <IntlMessages id="Auth.verifyTitle" />
                        </h1>
                    </div>

                    {authStore.verifyError && <Error>{authStore.verifyError}</Error>}

                    <div className="app-login-form">
                        {!authStore.verifyError && !props.match.params.uuid && <IntlMessages id="Auth.verifyContent" />}

                        {!authStore.verifyError && props.match.params.uuid && <CircularProgress />}

                        <div
                            className="mb-3 d-flex align-items-center justify-content-between"
                            style={{ paddingTop: 20 }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => {
                                    history.push(`/auth/email/signin/`);
                                }}
                                color="primary"
                            >
                                <IntlMessages id="Auth.signIn" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {authStore.isLoginInProgress && (
                <div className="loader-view">
                    <CircularProgress />
                </div>
            )}
        </div>
    ));
};
// isLoginInProcess
// dispatch(showAuthLoader());

export default Verify;
