//import ruProjectMessages from '../locales/healthylab/ru_RU.json';
//import ruProjectMessages from `../locales/${process.env.REACT_APP_PROJECT}/ru_RU.json`;
import ruCommonMessages from "../locales/ru_RU.json";

let RuLang;

if (process.env.REACT_APP_PROJECT === "zakaut") {
    const ruProjectMessages = require(`../locales/${process.env.REACT_APP_PROJECT}/ru_RU.json`);
    const ruCustomersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Customers/locales/ru_RU.json`);
    const ruCustomersOrdersMessages = require(
        `${process.env.REACT_APP_MODULES_DIR}/CustomersOrders/locales/ru_RU.json`,
    );
    const ruManagersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Managers/locales/ru_RU.json`);
    const ruAdminsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Admins/locales/ru_RU.json`);
    const ruServicesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Services/locales/ru_RU.json`);
    const ruTariffsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Tariffs/locales/ru_RU.json`);
    const ruCouponsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Coupons/locales/ru_RU.json`);
    const ruStaticQuestionsMessages = require(
        `${process.env.REACT_APP_MODULES_DIR}/StaticQuestions/locales/ru_RU.json`,
    );
    const ruAuthMessages = require(`modules/Auth/locales/ru_RU.json`);
    const ruUsersMessages = require(`modules/Users/locales/ru_RU.json`);
    const ruAnalyticsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Analytics/locales/ru_RU.json`);

    RuLang = {
        messages: {
            ...ruCommonMessages,
            ...ruProjectMessages,
            ...ruAnalyticsMessages,
            ...ruCustomersMessages,
            ...ruCustomersOrdersMessages,
            ...ruManagersMessages,
            ...ruAdminsMessages,
            ...ruServicesMessages,
            ...ruTariffsMessages,
            ...ruCouponsMessages,
            ...ruStaticQuestionsMessages,
            ...ruAuthMessages,
            ...ruUsersMessages,
        },
        locale: "ru-RU",
    };
}

if (process.env.REACT_APP_PROJECT === "moondo") {
    const ruProjectMessages = require(`../locales/${process.env.REACT_APP_PROJECT}/ru_RU.json`);
    const ruCustomersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Customers/locales/ru_RU.json`);
    const ruCustomersOrdersMessages = require(
        `${process.env.REACT_APP_MODULES_DIR}/CustomersOrders/locales/ru_RU.json`,
    );
    const ruManagersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Managers/locales/ru_RU.json`);
    const ruServicesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Services/locales/ru_RU.json`);
    const ruFeedbacksMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Feedbacks/locales/ru_RU.json`);
    const ruTariffsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Tariffs/locales/ru_RU.json`);
    const ruCouponsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Coupons/locales/ru_RU.json`);
    const ruStaticQuestionsMessages = require(
        `${process.env.REACT_APP_MODULES_DIR}/StaticQuestions/locales/ru_RU.json`,
    );
    const ruAuthMessages = require(`modules/Auth/locales/ru_RU.json`);
    const ruUsersMessages = require(`modules/Users/locales/ru_RU.json`);
    const ruAnalyticsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Analytics/locales/ru_RU.json`);

    RuLang = {
        messages: {
            ...ruCommonMessages,
            ...ruProjectMessages,
            ...ruAnalyticsMessages,
            ...ruCustomersMessages,
            ...ruCustomersOrdersMessages,
            ...ruManagersMessages,
            ...ruServicesMessages,
            ...ruFeedbacksMessages,
            ...ruTariffsMessages,
            ...ruCouponsMessages,
            ...ruStaticQuestionsMessages,
            ...ruAuthMessages,
            ...ruUsersMessages,
        },
        locale: "ru-RU",
    };
}

if (process.env.REACT_APP_PROJECT === "taxi") {
    const ruProjectMessages = require(`../locales/${process.env.REACT_APP_PROJECT}/ru_RU.json`);
    const ruCustomersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Customers/locales/ru_RU.json`);
    const ruCustomersReportsMessages = require(
        `${process.env.REACT_APP_MODULES_DIR}/CustomersReports/locales/ru_RU.json`,
    );
    const ruTariffsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Tariffs/locales/ru_RU.json`);
    const ruCitiesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Cities/locales/ru_RU.json`);
    const ruCoordinatorsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Coordinators/locales/ru_RU.json`);
    const ruProvidersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Providers/locales/ru_RU.json`);
    const ruEmployeesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Employees/locales/ru_RU.json`);
    const ruHelpRequestsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/HelpRequests/locales/ru_RU.json`);
    const ruOrdersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Orders/locales/ru_RU.json`);
    const ruManagersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Managers/locales/ru_RU.json`);
    const ruAccessTokensMessages = require(`${process.env.REACT_APP_MODULES_DIR}/AccessTokens/locales/ru_RU.json`);
    const ruAdminsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Admins/locales/ru_RU.json`);
    const ruRolesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Roles/locales/ru_RU.json`);
    const ruAuthMessages = require(`modules/Auth/locales/ru_RU.json`);
    const ruUsersMessages = require(`modules/Users/locales/ru_RU.json`);
    const ruAnalyticsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Analytics/locales/ru_RU.json`);

    RuLang = {
        messages: {
            ...ruCommonMessages,
            ...ruProjectMessages,
            ...ruAnalyticsMessages,
            ...ruCustomersMessages,
            ...ruCustomersReportsMessages,
            ...ruCoordinatorsMessages,
            ...ruProvidersMessages,
            ...ruTariffsMessages,
            ...ruCitiesMessages,
            ...ruEmployeesMessages,
            ...ruOrdersMessages,
            ...ruHelpRequestsMessages,
            ...ruAdminsMessages,
            ...ruManagersMessages,
            ...ruAccessTokensMessages,
            ...ruRolesMessages,
            ...ruAuthMessages,
            ...ruUsersMessages,
        },
        locale: "ru-RU",
    };
}

if (process.env.REACT_APP_PROJECT === "healthylab") {
    const ruProjectMessages = require(`../locales/${process.env.REACT_APP_PROJECT}/ru_RU.json`);
    const ruCustomersMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Customers/locales/ru_RU.json`);
    const ruEmployeesMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Employees/locales/ru_RU.json`);
    const ruSiteRequestsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/SiteRequests/locales/ru_RU.json`);
    const ruAuthMessages = require(`modules/Auth/locales/ru_RU.json`);
    const ruUsersMessages = require(`modules/Users/locales/ru_RU.json`);
    const ruAnalyticsMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Analytics/locales/ru_RU.json`);
    const ruQuizMessages = require(`${process.env.REACT_APP_MODULES_DIR}/Quiz/locales/ru_RU.json`);

    RuLang = {
        messages: {
            ...ruCommonMessages,
            ...ruProjectMessages,
            ...ruAnalyticsMessages,
            ...ruCustomersMessages,
            ...ruEmployeesMessages,
            ...ruSiteRequestsMessages,
            ...ruQuizMessages,
            ...ruAuthMessages,
            ...ruUsersMessages,
        },
        locale: "ru-RU",
    };
}

export default RuLang;
