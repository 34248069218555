import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";
import { useStores } from "modules/stores";

import { NavLink, withRouter } from "react-router-dom";

import IntlMessages from "util/IntlMessages";

import Button from "@material-ui/core/Button";

const Menu = () => {
    const { usersStore, authStore } = useStores();
    const { history } = useHistory();

    /*
  useEffect(() => {
    //if use cache true. then it's not loaded for direct open detail page.
    //store.fetchList(false, {}, "mtime desc", 0, 1000, true);


    const pathname = `#${history.location.pathname}`;// get current path
    const mainMenu = document.getElementsByClassName('nav-item');
    for (let i = 0; i < mainMenu.length; i++) {
      mainMenu[i].onclick = function () {
        for (let j = 0; j < mainMenu.length; j++) {
          if (mainMenu[j].classList.contains('active')) {
            mainMenu[j].classList.remove('active')
          }
        }
        this.classList.toggle('active');
      }
    }
    const subMenuLi = document.getElementsByClassName('nav-arrow');
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function () {
        for (let j = 0; j < subMenuLi.length; j++) {
          if (subMenuLi[j].classList.contains('active')) {
            subMenuLi[j].classList.remove('active')
          }
        }
        this.classList.toggle('active');
      }
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('active');
      } else {
        this.closest(activeLi, 'li').classList.add('active');
      }
      const parentNav = this.closest(activeNav, '.nav-item');
      if (parentNav) {
        parentNav.classList.add('active');
      }

    } catch (e) {

    }
  }, []);

   */

    const closest = (el, selector) => {
        try {
            let matchesFn;
            // find vendor prefix
            ["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"].some(
                function (fn) {
                    if (typeof document.body[fn] === "function") {
                        matchesFn = fn;
                        return true;
                    }
                    return false;
                },
            );

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {}

        return null;
    };

    const zakautTopMenu = () => {
        return (
            <div className="app-main-menu d-none d-md-block">
                {usersStore.role === "customer" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/${usersStore.me.detail.id}`}>
                                <span className="nav-link">Главная страница</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/${usersStore.me.detail.id}/info`}>
                                <span className="nav-link">Мой профиль</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/${usersStore.me.detail.id}/services`}>
                                <span className="nav-link">Услуги</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/${usersStore.me.detail.id}/orders`}>
                                <span className="nav-link">Заказы</span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon"  to={`/app/customers/${usersStore.me.detail.id}/files`}>
                    <span className="nav-link">Файлы</span>
                  </NavLink>
                </li>
                */}
                    </ul>
                ) : (
                    ""
                )}

                {usersStore.role === "manager" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/`}>
                                <span className="nav-link">Клиенты</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers-orders/`}>
                                <span className="nav-link">Заказы</span>
                            </NavLink>
                        </li>
                    </ul>
                ) : (
                    ""
                )}
            </div>
        );
    };

    const taxiTopMenu = () => {
        return (
            <div className="app-main-menu d-none d-md-block">
                {usersStore.role === "customer" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/analytics`}>
                                <span className="nav-link">Аналитика</span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon" to={`/app/customers/me`}>
                    <span className="nav-link">Профиль компании</span>
                  </NavLink>
                </li>
                */}
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/employees`}>
                                <span className="nav-link">Сотрудники</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/coordinators`}>
                                <span className="nav-link">Координаторы</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/orders`}>
                                <span className="nav-link">Поездки</span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon" to={`/app/tariffs`}>
                    <span className="nav-link">Тарифы</span>
                  </NavLink>
                </li>
                */}
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/roles`}>
                                <span className="nav-link">Политика поездок</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/reports/`}>
                                <span className="nav-link">Отчеты</span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon" to={`/app/files/`}>
                    <span className="nav-link">Документы</span>
                  </NavLink>
                </li>
                */}

                        <li className="nav-item" style={{ marginLeft: "auto" }}>
                            <NavLink className="prepend-icon" to={`/app/orders/new`}>
                                <span className="nav-link" style={{ textDecoration: "underline" }}>
                                    Новый заказ
                                </span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon"  to={`/app/customers/${usersStore.me.detail.id}/files`}>
                    <span className="nav-link">Файлы</span>
                  </NavLink>
                </li>
                */}
                    </ul>
                ) : (
                    ""
                )}

                {usersStore.role === "coordinator" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/analytics`}>
                                <span className="nav-link">Аналитика</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/employees`}>
                                <span className="nav-link">Сотрудники</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/roles`}>
                                <span className="nav-link">Политика поездок</span>
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/reports/`}>
                                <span className="nav-link">Отчеты</span>
                            </NavLink>
                        </li>

                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon" to={`/app/files/`}>
                    <span className="nav-link">Документы</span>
                  </NavLink>
                </li>
                */}

                        <li className="nav-item" style={{ marginLeft: "auto" }}>
                            <NavLink className="prepend-icon" to={`/app/orders/new`}>
                                <span className="nav-link" style={{ textDecoration: "underline" }}>
                                    Новый заказ
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                ) : (
                    ""
                )}

                {usersStore.role === "operator" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/orders`}>
                                <span className="nav-link">Поездки</span>
                            </NavLink>
                        </li>

                        <li className="nav-item" style={{ marginLeft: "auto" }}>
                            <NavLink className="prepend-icon" to={`/app/orders/new`}>
                                <span className="nav-link" style={{ textDecoration: "underline" }}>
                                    Новый заказ
                                </span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon"  to={`/app/customers/${usersStore.me.detail.id}/files`}>
                    <span className="nav-link">Файлы</span>
                  </NavLink>
                </li>
                */}
                    </ul>
                ) : (
                    ""
                )}

                {usersStore.role === "employee" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/orders`}>
                                <span className="nav-link">Поездки</span>
                            </NavLink>
                        </li>

                        <li className="nav-item" style={{ marginLeft: "auto" }}>
                            <NavLink className="prepend-icon" to={`/app/orders/new`}>
                                <span className="nav-link" style={{ textDecoration: "underline" }}>
                                    Новый заказ
                                </span>
                            </NavLink>
                        </li>
                        {/*
                <li className="nav-item">
                  <NavLink className="prepend-icon"  to={`/app/customers/${usersStore.me.detail.id}/files`}>
                    <span className="nav-link">Файлы</span>
                  </NavLink>
                </li>
                */}
                    </ul>
                ) : (
                    ""
                )}

                {usersStore.role === "manager" ? (
                    <ul className="navbar-nav navbar-nav-mega">
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/analytics`}>
                                <span className="nav-link">Аналитика</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/customers/`}>
                                <span className="nav-link">Клиенты</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/reports/`}>
                                <span className="nav-link">Заказы</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="prepend-icon" to={`/app/tariffs/`}>
                                <span className="nav-link">Тарифные сетки</span>
                            </NavLink>
                        </li>
                    </ul>
                ) : (
                    ""
                )}
            </div>
        );
    };

    if (process.env.REACT_APP_PROJECT === "zakaut") {
        return zakautTopMenu();
    }

    if (process.env.REACT_APP_PROJECT === "taxi") {
        return taxiTopMenu();
    }
};

export default withRouter(Menu);
