import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard/index";

const Router = ({ match, store }) => {
    return (
        <Switch>
            <Route
                exact
                path={`${match.url}`}
                component={() => <Dashboard store={store} id={match.params?.record_id} />}
            />
        </Switch>
    );
};

export default Router;
