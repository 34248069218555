import { decorate, observable, action, runInAction } from 'mobx';
import { getErrorMessage, getValidationErrors } from 'modules/WebApi';
import CommonStore from "modules/CommonStore";
import CitiesAirportsAPI from "./api"

class cititesAirportsStore extends CommonStore {
  constructor(rootStore: RootStoreInterface) {
    const apiClient = new CitiesAirportsAPI(rootStore.apiClient);

    super(
        rootStore,
        apiClient,
        rootStore.specifications["CitiesAirports"]
    );
  }
}

decorate(cititesAirportsStore, {

});

export default cititesAirportsStore;
