import React, { useEffect, useState } from "react";
import { reaction } from "mobx";
import { useDispatch, useSelector } from "react-redux";
import { useStores } from "modules/stores";
import { useHistory } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import Error from "components/Errors/Error";
import LogoVertical from "assets/components/LogoVertical";
import EditField from "components/table/EditField";
/*
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from 'actions/Auth';

 */

const SignUp = (props) => {
    const { authStore } = useStores();
    const history = useHistory();

    const [email, setEmail] = useState("");

    useEffect(() => {
        reaction(
            () => authStore.isRequestPasswordEmailSuccess,
            (isRequestPasswordEmailSuccess) => {
                if (isRequestPasswordEmailSuccess) {
                    history.push(`/auth/email/signin${props.match.params.code ? `/${props.match.params.code}` : ""}`);
                }
            },
        );
    }, []);

    return useObserver(() => {
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <LogoVertical />

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>
                                <IntlMessages id="Auth.forgotPasswordTitle" />
                            </h1>
                        </div>

                        {authStore.isRequestPasswordEmailError && (
                            <Error>{authStore.isRequestPasswordEmailError}</Error>
                        )}

                        <div className="app-login-form">
                            <form method="post" action="/">
                                <EditField
                                    field={{ name: "email", type: "email" }}
                                    onChange={(field, value, event) => setEmail(value)}
                                    store={{ specification: { moduleName: "SignIn" } }}
                                    value={email}
                                    variant="standard"
                                />

                                <div
                                    className="mb-3 d-flex align-items-center justify-content-between"
                                    style={{ paddingTop: 20 }}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            authStore.userRequestResetPassword({ email });
                                        }}
                                        color="primary"
                                    >
                                        <IntlMessages id="Auth.forgotPasswordButton" />
                                    </Button>
                                    <Link
                                        to={`/auth/email/signin${props.match.params.code ? `/${props.match.params.code}` : ""}`}
                                    >
                                        <IntlMessages id="Auth.alreadyMember" />
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {authStore.isRequestPasswordEmailInProcess && (
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                )}
            </div>
        );
    });
};

export default SignUp;
