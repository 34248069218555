import React from "react";
import { useStores } from "modules/stores";
import AppLayouts from "./AppLayouts";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "util/asyncComponent";

const INSIDE_THE_HEADER = "inside_the_header";
const HORIZONTAL_NAVIGATION = "horizontal_navigation";
const VERTICAL_NAVIGATION = "vertical_navigation";

const AppLayout = ({ match }) => {
    const { usersStore } = useStores();

    /*
  const horizontalNavPosition = INSIDE_THE_HEADER;// useSelector(({settings}) => settings.horizontalNavPosition);
  const navigationStyle = usersStore.role !== "admin" ? HORIZONTAL_NAVIGATION : VERTICAL_NAVIGATION;

  const onGetLayout = (layout) => {

    return "BelowHeaderNav";

    switch (layout) {
      case "inside_the_header":
        return "InsideHeaderNav";

      case "above_the_header":
        return "AboveHeaderNav";

      case "below_the_header":
        return "BelowHeaderNav";
      default:
        return "Vertical";
    }
  };
  //const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];
  */

    let Layout;
    if (usersStore.role === "admin") {
        Layout = AppLayouts["Vertical"];
    } else if (usersStore.role === "customer" && process.env.REACT_APP_PROJECT === "zakaut") {
        Layout = AppLayouts["Empty"];
    } else {
        Layout = AppLayouts["BelowHeaderNav"];
    }

    return (
        <Layout>
            <Switch>
                <Route
                    path={`${match.url}`}
                    component={asyncComponent(() => import(`${process.env.REACT_APP_MODULES_DIR}/route`))}
                />
            </Switch>
        </Layout>
    );
};

export default AppLayout;
