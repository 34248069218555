import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "components/Navigation";

const SideBarContent = () => {
    const navigationMenus = require(`${process.env.REACT_APP_MODULES_DIR}/menu.js`).default;

    return (
        <CustomScrollbars className=" scrollbar">
            <Navigation menuItems={navigationMenus} />
        </CustomScrollbars>
    );
};

export default SideBarContent;
