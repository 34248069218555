import { decorate, observable, action, runInAction } from 'mobx';
import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
//import { StoreInterface, RootStoreInterface } from 'interfaces';
import CommonStore from "modules/CommonStore";
import ApiClient from "./api";

class CitiesProvidersStore extends CommonStore {
  constructor(rootStore: RootStoreInterface) {
    const apiClient = new ApiClient(rootStore.apiClient);
    super(
      rootStore,
      apiClient,
      rootStore.specifications["CitiesProviders"]
    );
  }
}


decorate(CitiesProvidersStore, {

});



export default CitiesProvidersStore;
