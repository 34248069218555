import React, { useState } from "react";
import { Link, withRouter, useHistory, NavLink } from "react-router-dom";
//import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
//import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
//import SearchBox from "components/SearchBox";
//import CardHeader from "components/dashboard/Common/CardHeader/index";
//import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import UserInfo from "components/UserInfo";
// import {userSignOut} from 'actions/Auth';
import MenuItem from "@material-ui/core/MenuItem";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { useStores } from "modules/stores";

import ContainerHeader from "components/ContainerHeader/index";

import Avatar from "@material-ui/core/Avatar";
import LogoHorizontal from "assets/components/LogoHorizontal";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Error from "components/Errors/Error";
import EditField from "components/table/EditField";
import SettingDialog from "modules/Users/routes/settings";

const FIXED_DRAWER = "fixed_drawer";
const COLLAPSED_DRAWER = "collapsible";

const Index = (props) => {
    const { usersStore, authStore } = useStores();
    const history = useHistory();

    //const dispatch = useDispatch();
    //const { drawerType, locale, navCollapsed } = useSelector(({ settings }) => settings);
    const navCollapsed = false;
    const drawerType = FIXED_DRAWER;

    const [langSwitcher, setLangSwitcher] = useState(false);
    const [mailNotification, setMailNotification] = useState(false);
    const [appNotification, setAppNotification] = useState(false);
    const [searchBox, setSearchBox] = useState(false);
    const [apps, setApps] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isSettingsDialogOpened, setSettingsDialogOpened] = useState(false);

    const onAppNotificationSelect = () => {
        setAppNotification(!appNotification);
    };

    const onMailNotificationSelect = () => {
        setMailNotification(!mailNotification);
    };
    const onLangSwitcherSelect = (event) => {
        setLangSwitcher(!langSwitcher);
    };

    const onSearchBoxSelect = () => {
        setSearchBox(!searchBox);
    };
    const onAppsSelect = () => {
        setApps(!apps);
    };

    const handleRequestClose = () => {
        setSearchBox(false);
        setLangSwitcher(false);
        setMailNotification(false);
        setSearchBox(false);
        setApps(false);
    };

    const onToggleCollapsedNav = (e) => {
        //const val = !navCollapsed;
        //dispatch(toggleCollapsedNav(val));
    };

    const Apps = () => (
        <ul className="jr-list jr-list-half">
            <li className="jr-list-item">
                <Link className="jr-list-link" to="/app/calendar/basic">
                    <i className="zmdi zmdi-calendar zmdi-hc-fw" />
                    <span className="jr-list-text">
                        <IntlMessages id="sidebar.calendar.basic" />
                    </span>
                </Link>
            </li>

            <li className="jr-list-item">
                <Link className="jr-list-link" to="/app/to-do">
                    <i className="zmdi zmdi-check-square zmdi-hc-fw" />
                    <span className="jr-list-text">
                        <IntlMessages id="sidebar.appModule.toDo" />
                    </span>
                </Link>
            </li>

            <li className="jr-list-item">
                <Link className="jr-list-link" to="/app/mail">
                    <i className="zmdi zmdi-email zmdi-hc-fw" />
                    <span className="jr-list-text">
                        <IntlMessages id="sidebar.appModule.mail" />
                    </span>
                </Link>
            </li>

            <li className="jr-list-item">
                <Link className="jr-list-link" to="/app/chat">
                    <i className="zmdi zmdi-comment zmdi-hc-fw" />
                    <span className="jr-list-text">
                        <IntlMessages id="sidebar.appModule.chat" />
                    </span>
                </Link>
            </li>

            <li className="jr-list-item">
                <Link className="jr-list-link" to="/app/contact">
                    <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                    <span className="jr-list-text">
                        <IntlMessages id="sidebar.appModule.contact" />
                    </span>
                </Link>
            </li>

            <li className="jr-list-item">
                <Link className="jr-list-link" to="/">
                    <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
                    <span className="jr-list-text">Add New</span>
                </Link>
            </li>
        </ul>
    );

    const localStore = useLocalStore(() => ({
        schema: "settings",
    }));

    const onSwitchLanguage = (lang) => {
        usersStore.saveUserInfo({ lang_id: lang.id });
    };

    const handleSettingsOpen = () => {
        setSettingsDialogOpened(true);
        onAppsSelect();
    };

    const handleSettingsClose = () => {
        setSettingsDialogOpened(false);
    };

    const handlePropfileOpen = () => {
        onAppsSelect();
        history.push(usersStore.me.profilePage);
    };

    const updateSearchText = (evt) => {
        setSearchText(evt.target.value);
    };

    const drawerStyle = drawerType.includes(FIXED_DRAWER)
        ? "d-block d-xl-none"
        : drawerType.includes(COLLAPSED_DRAWER)
          ? "d-block"
          : "d-none";

    return useObserver(() => (
        <AppBar className="app-main-header">
            <Toolbar className="app-toolbar" disableGutters={false}>
                {usersStore.role !== "admin" ? (
                    <Link className="app-sidebar-logo mr-2 d-none d-sm-block" to={usersStore.homePage}>
                        <LogoHorizontal />
                    </Link>
                ) : (
                    ""
                )}

                {usersStore.role === "moderator" ? (
                    <div className={`app-top-2nav d-none d-md-block ${props.styleName}`}>
                        <div className="d-flex app-toolbar align-items-center">
                            <div className="app-main-menu d-none d-md-block">
                                <ul className="navbar-nav navbar-nav-mega">
                                    <li className="nav-item">
                                        <NavLink className="prepend-icon" to={usersStore.homePage}>
                                            <span className="nav-link" style={{ color: "#fff" }}>
                                                Компания
                                            </span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <ul className="header-notifications list-inline ml-auto">
                    <li className="list-inline-item">
                        <Dropdown className="quick-menu app-notification" isOpen={apps} toggle={onAppsSelect}>
                            <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                                <span className="app-notification-menu">
                                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                                    <span>{usersStore.me ? usersStore.me.name : ""}</span>
                                </span>
                            </DropdownToggle>

                            <DropdownMenu>
                                {process.env.REACT_APP_PROJECT === "taxi" && (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                history.push("/app/orders/new");
                                            }}
                                        >
                                            <i className="zmdi zmdi-open-in-new zmdi-hc-fw mr-2" />
                                            Новый заказ
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                history.push("/app/orders");
                                            }}
                                        >
                                            <i className="zmdi zmdi-border-all zmdi-hc-fw mr-2" />
                                            Заказы
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                history.push("/app/employees");
                                            }}
                                        >
                                            <i className="zmdi zmdi-group-work zmdi-hc-fw mr-2" />
                                            Сотрудники
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                history.push("/app/analytics");
                                            }}
                                        >
                                            <i className="zmdi zmdi-graphic-eq zmdi-hc-fw mr-2" />
                                            Аналитика
                                        </MenuItem>
                                    </>
                                )}

                                <MenuItem onClick={handleSettingsOpen}>
                                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
                                    <IntlMessages id="popup.settings" />
                                </MenuItem>

                                {usersStore.me.profilePage && (
                                    <MenuItem onClick={handlePropfileOpen}>
                                        <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
                                        <IntlMessages id="popup.profile" />
                                    </MenuItem>
                                )}

                                <MenuItem
                                    onClick={async () => {
                                        onAppsSelect();
                                        await authStore.userSignOut();
                                        //we have problems with logout
                                        document.location = "/";
                                    }}
                                >
                                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

                                    <IntlMessages id="popup.logout" />
                                </MenuItem>
                            </DropdownMenu>
                        </Dropdown>
                    </li>

                    {isSettingsDialogOpened ? <SettingDialog onClose={handleSettingsClose} /> : ""}

                    {process.env.REACT_APP_PROJECT === "zakaut" && (
                        <li className="list-inline-item">
                            <Dropdown className="quick-menu" isOpen={langSwitcher} toggle={onLangSwitcherSelect}>
                                <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                                    <IconButton className="icon-btn">
                                        <i
                                            className={`flag flag-24 flag-${usersStore.me.lang && usersStore.me.lang.icon ? usersStore.me.lang.icon : "???"}`}
                                        />
                                    </IconButton>
                                </DropdownToggle>

                                <DropdownMenu right className="w-50">
                                    <LanguageSwitcher
                                        switchLanguage={onSwitchLanguage}
                                        handleRequestClose={handleRequestClose}
                                    />
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    )}

                    {process.env.REACT_APP_PROJECT === "healthylab" &&
                        usersStore.role !== "admin" &&
                        (usersStore.role !== "moderator" || usersStore.me.detail.customer_id === 6) && (
                            <li className="list-inline-item mail-tour">
                                <Link className="jr-list-link" to="/app/chat/me">
                                    <IconButton className="icon-btn" style={{ color: "#fff" }}>
                                        <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
                                    </IconButton>
                                </Link>
                            </li>
                        )}
                </ul>

                <div className="ellipse-shape" />
            </Toolbar>
        </AppBar>
    ));
};

export default withRouter(Index);
