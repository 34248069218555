import { decorate, observable, action, runInAction } from 'mobx';
import CommonStore from "modules/CommonStore";
import ApiClient from "./api";

class OrdersProvidersInfoStore extends CommonStore {
  constructor(rootStore: RootStoreInterface) {
    const apiClient = new ApiClient(rootStore.apiClient);
    super(
      rootStore,
      apiClient,
      rootStore.specifications["OrdersProvidersInfo"]
    );
  }

}

decorate(OrdersProvidersInfoStore, {

});

export default OrdersProvidersInfoStore;
