import { decorate } from "mobx";
import CommonStore from "modules/CommonStore";
import TariffsAPI from "./api";

class tariffsStore extends CommonStore {
    constructor(rootStore) {
        const apiClient = new TariffsAPI(rootStore.apiClient);

        super(rootStore, apiClient, rootStore.specifications.TariffsPriceTaker);
    }
}

decorate(tariffsStore, {});
export default tariffsStore;
