import { decorate, observable, action, runInAction } from 'mobx';
import CommonStore from "modules/CommonStore";
import MailerAPI from "./api"

class mailerStore extends CommonStore {
  constructor(rootStore: RootStoreInterface) {
    const apiClient = new MailerAPI(rootStore.apiClient);
    super(
        rootStore,
        apiClient,
        rootStore.specifications["Mailer"]
    );
  }
}

decorate(mailerStore, {

});

export default mailerStore;
