import { decorate, observable, action, runInAction } from 'mobx';
import CommonStore from "modules/CommonStore";
import AnalyticsAPI from "./api";

class AnalyticsStore extends CommonStore {
    constructor(rootStore: RootStoreInterface) {
        const apiClient = new AnalyticsAPI(rootStore.apiClient);
        super(
            rootStore,
            apiClient,
            rootStore.specifications["Analytics"]
        );
    }



    async fetchAdminStats(dateStart, dateEnd): Promise<void>
    {
        runInAction(() => {
            this.combinedFetchingError = "";
            this.isCombinedFetched = false;
        });
        try {
            const response = await this.apiClient.getAdminStats(dateStart, dateEnd);
            runInAction(() => {
                this.combined = {... response};
                this.isCombinedFetched = true;
            });
        } catch (err) {
            runInAction(() => {
                this.combinedFetchingError = err;
                this.isCombinedFetched = true;
            });
        }
    }




    async fetchManagerStats(dateStart, dateEnd): Promise<void>
    {
        runInAction(() => {
            this.managerStatsFetchingError = "";
            this.isManagerStatsFetched = false;
        });
        try {
            const response = await this.apiClient.getManagerStats(dateStart, dateEnd);
            runInAction(() => {
                this.managerStats = response;
                this.isManagerStatsFetched = true;
            });
        } catch (err) {
            runInAction(() => {
                this.managerStatsFetchingError = err;
                this.isManagerStatsFetched = true;
            });
        }
    }


    async fetchCustomerStats(customerId, dateStart, dateEnd): Promise<void> {
        runInAction(() => {
            this.customerStatsFetchingError = "";
            this.isCustomerStatsFetched = false;
        });

        try {
            const response = await this.apiClient.getCustomerStats(customerId, dateStart, dateEnd);
            runInAction(() => {
                this.customerStats = response;
                this.isCustomerStatsFetched = true;
            });
        } catch (err) {
            runInAction(() => {
                this.customerStatsFetchingError = err;
                this.isCustomerStatsFetched = true;
            });
        }
    }

}

decorate(AnalyticsStore, {
    isCombinedFetched: observable,
    combinedFetchingError: observable,
    fetchCombined: action,
    combined: observable,



    managerStats: observable,
    managerStatsFetchingError: observable,
    isManagerStatsFetched: observable,
    fetchManagerStats: action,


    customerStats: observable,
    customerStatsFetchingError: observable,
    isCustomerStatsFetched: observable,
    fetchCustomerStats: action,

});

export default AnalyticsStore;
