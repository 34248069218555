import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import Widget from "components/Widget";
import IntlMessages from "util/IntlMessages";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditField from "./EditField";
import { useStores } from "modules/stores";
import Avatar from "components/table/Avatar";

const Info = (props) => {
    const { store, onCancel } = props;

    const { usersStore } = useStores();

    const [modify, setModify] = useState(!!props.modify);
    const [form, setForm] = useState({ ...store.record });
    const [formSave, setFormSave] = useState({});

    const handleFormChange = (field, value, event) => {
        setForm({ ...form, ...{ [field.name]: value } });
        setFormSave({ ...formSave, ...{ [field.name]: value } });
    };
    const handleFormSave = () => {
        setModify(false);
        const toSave = Object.keys(formSave).reduce(
            (a, k) => ({ ...a, [k]: formSave[k] === "" ? null : formSave[k] }),
            {},
        );
        store.record.update(toSave);
        if (props.onSave) props.onSave(toSave);
    };

    const getFieldValue = (fieldName, record, field) => {
        if (props.customFieldsRenderer && props.customFieldsRenderer[fieldName]) {
            return props.customFieldsRenderer[fieldName](record, field, record[fieldName]);
        }
        if (fieldName.match(/_id$/)) {
            const objectFieldName = fieldName.replace(/_id$/, "");
            if (typeof record[objectFieldName] === "object") {
                return (
                    <IntlMessages
                        defaultMessage={record[objectFieldName].name}
                        id={`${store.specification.moduleName}.schema.${fieldName}.${record[objectFieldName].name}`}
                    />
                );
            }
        }
        if (field && field.formType === "select" && !!field.multiple) {
            try {
                return record[fieldName]
                    .map((item) => field.link.list.find((f) => f.id == item))
                    .map((item) => (item && item.name ? item.name : ""))
                    .join(", ");
            } catch (e) {
                return "";
            }
        }

        if (typeof record[fieldName] === "object") {
            return (
                <IntlMessages
                    defaultMessage={record[fieldName].name}
                    id={`${store.specification.moduleName}.schema.${fieldName}.${record[fieldName].name}`}
                />
            );
        }

        if (field && field.type === "email") {
            return (
                <a
                    target="_new"
                    href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&su=&body=Здравствуйте, ${store.record.name}!%0A%0A%0A--%0A${usersStore.me.name}%0A${usersStore.me.email}&to=${record[fieldName]}`}
                >
                    {record[fieldName]}
                </a>
            );
        }
        if (field && field.type === "avatar") {
            //return (<Avatar size={60} record={record}/>);
            return (
                <a target="_new" href={record[fieldName]}>
                    <IntlMessages id={`avatar.view`} />
                </a>
            );
        }
        if (
            (field && field.type === "url") ||
            (typeof record[fieldName] === "string" && record[fieldName].match(/^http.?:\/\//))
        ) {
            return (
                <a target="_new" href={`${record[fieldName]}`}>
                    Открыть
                </a>
            );
        }
        if (field && field.type === "boolean") {
            //return (<Avatar size={60} record={record}/>);
            return record[fieldName] ? "Да" : "Нет";
        }
        if (field && field.type === "date") {
            //return (<Avatar size={60} record={record}/>);
            return record[fieldName].replace(/T.*/g, "");
        }

        if (field && field.link && field.link.list) {
            const item = field.link.list.find((item) => item.id === record[fieldName]);
            if (item && item.name) {
                return (
                    <IntlMessages
                        defaultMessage={item.name}
                        id={`${store.specification.moduleName}.schema.${fieldName}.${item.name}`}
                    />
                );
            } else {
                return record[fieldName];
            }
        }

        return record[fieldName];
    };

    const getFieldJsonValue = (field, value) => {
        if (field.type === "json" && typeof value === "object" && value !== null) {
            return Object.keys(value).map((key, index) => (
                <div key={key}>
                    <b>{key}: </b>
                    <span>{value[key]}</span>
                </div>
            ));
        }
        return "!";
    };

    const renderField = (field, key) => {
        //if (props.filter && props.filter[field.name] && hideFilterFields) return;

        if (field.formVisible === false) return;
        if (typeof field.formVisible === "object") {
            const fieldValue =
                form[field.formVisible.field] && form[field.formVisible.field].name
                    ? form[field.formVisible.field].name
                    : form[field.formVisible.field];
            /*if (field.formVisible.value !== undefined) { //TODO!!!!!!! change to equal

                if (Array.isArray(field.formVisible.value)) {
                    if (!field.formVisible.value.includes(fieldValue)) {
                        return;
                    }
                } else if (form[field.formVisible.field] != field.formVisible.value) { // don't use !== here
                    return;
                }
            } else */
            if (field.formVisible.equal !== undefined) {
                if (Array.isArray(field.formVisible.equal)) {
                    if (!field.formVisible.equal.includes(fieldValue)) {
                        return;
                    }
                } else if (form[field.formVisible.field] != field.formVisible.equal) {
                    // don't use !== here
                    return;
                }
            } else if (field.formVisible.notequal !== undefined) {
                //TODO!!!!!!! change to equal
                if (Array.isArray(field.formVisible.notequal)) {
                    if (field.formVisible.notequal.includes(fieldValue)) {
                        return;
                    }
                } else if (
                    form[field.formVisible.field] == field.formVisible.notequal ||
                    (form[field.formVisible.field] === undefined && field.formVisible.notequal == "")
                ) {
                    // don't use !== here
                    return;
                }
            }
        }

        if (field.formVisible === "add" && form.id !== undefined) {
            return;
        }
        if (field.formVisible === "edit" && form.id === undefined) {
            return;
        }

        //if (customEditFieldsRenderer && customEditFieldsRenderer[field.name]) {
        //    return customEditFieldsRenderer[field.name](field, handleFormChange, form && form[field.name]);
        //}

        return (
            <EditField
                key={key}
                filter={props.filter}
                //intlPrefix={intlPrefix}
                store={store}
                field={field}
                value={form[field.name]}
                onChange={handleFormChange}
            />
        );
    };

    return useObserver(() => {
        const { record } = store;

        if (!record) return <div>Record isn't defined</div>;

        return (
            <Widget title={props.title} styleName="jr-card-profile-sm">
                <CardContent style={{ padding: 0 }}>
                    {props.fields.map((fieldName, index) => {
                        if (modify) {
                            const field = store.specification.fields.find((field) => field.name === fieldName);
                            if (field && (!field.link || field.link.type != "many")) {
                                return renderField(field, index);
                                /*
                                return (
                                    <EditField
                                        store={store}
                                        field={field}
                                        value={form[fieldName]}
                                        filter={props.filter}
                                        onChange={handleFormChange}
                                        autoFocus={props.autoFocus || false}
                                    />
                                );
                                */
                            }
                        } else {
                            if (record[fieldName]) {
                                let field = store.specification.fields.find((field) => field.name === fieldName);
                                if (field && field.type === "json") {
                                    return getFieldJsonValue(field, record[fieldName]);
                                } else {
                                    return (
                                        <div className="media-body">
                                            <span
                                                className="mb-0 text-grey jr-fs-sm"
                                                style={{ float: "left", marginRight: 15 }}
                                            >
                                                <IntlMessages
                                                    id={`${store.specification.moduleName}.schema.${fieldName}`}
                                                />
                                            </span>
                                            <p className="mb-0">{getFieldValue(fieldName, record, field)}</p>
                                        </div>
                                    );
                                }
                            }
                        }
                    })}
                </CardContent>
                {props.editingAllowed !== false ? (
                    <CardActions style={{ marginLeft: -12 }}>
                        {!modify ? (
                            <Button color="primary" size="small" onClick={() => setModify(true)}>
                                Редактировать
                            </Button>
                        ) : (
                            <Button color="primary" size="small" onClick={handleFormSave}>
                                Сохранить
                            </Button>
                        )}
                        {!!onCancel && (
                            <Button color="secondary" size="small" onClick={onCancel}>
                                Отменить
                            </Button>
                        )}
                    </CardActions>
                ) : (
                    ""
                )}
            </Widget>
        );
    });
};

export default Info;
