import CommonCRUD from "modules/CommonCRUD";

class Orders extends CommonCRUD {
    async recalcOrder(orderId, isRefresh = false) {
        return await this.api.fetch({
            method: "post",
            url: `${this.schema}/${orderId}/recalc`,
            ...(isRefresh ? { data: { isRefresh } } : {}),
        });
    }

    async repost(orderId) {
        return await this.api.fetch({
            method: "post",
            url: `${this.schema}/${orderId}/repost`,
        });
    }
}

export default Orders;
