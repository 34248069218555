import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from "mobx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import IntlMessages from "util/IntlMessages";
import { Alert } from "reactstrap";
import FileBase64 from "react-file-base64";
import { useIntl } from "react-intl";
import Error from "components/Errors/Error";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EditField from "./EditField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ExportDialog = (props) => {
    const { store } = props;

    const [form, setForm] = useState({});
    const [selected, setSelected] = useState([...props.exportFields]);

    useEffect(() => {
        reaction(
            () => store.editingRecord,
            (editingRecord) => {
                setForm(store.editingRecord);
                //console.log("!!!!!!!!!!!!!!!!!!! FORM", JSON.stringify(form));
            },
        );
    }, []);

    const handleFormChange = (ev) => {
        setForm({ ...form, [ev.target.name]: ev.target.value });
        //console.log("!!!!!!!!!!!!!!!!!!! FORM 2", JSON.stringify(form));
    };

    const capitalize = (s) => {
        if (typeof s !== "string") return "";
        return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const onChangeCheckboxHandle = (event, item) => {
        setSelected((selected) => {
            const new_selected = [...selected];

            //alert(selected);
            const pos = new_selected.findIndex((el) => el === item);
            if (event.target.checked) {
                if (pos === -1) new_selected.push(item);
            } else {
                if (pos !== -1) new_selected.splice(pos, 1);
            }
            //alert(selected);

            //selected[item] = event.target.checked;
            return new_selected;
        });
    };

    return useObserver(() => {
        return (
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle>Export</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.exportFields.map((item, index) => (
                            <FormControl fullWidth component="fieldset" key={index}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selected.findIndex((el) => el === item) !== -1}
                                            onChange={(event) => onChangeCheckboxHandle(event, item)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <div>
                                            <IntlMessages
                                                defaultMessage={capitalize(item)}
                                                id={`${store.specification ? store.specification.moduleName : ""}.export.${item}`}
                                            />
                                        </div>
                                    }
                                />
                            </FormControl>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="secondary">
                        <IntlMessages id={`dialog.cancel`} />
                    </Button>
                    <Button
                        onClick={() => {
                            props.onExport(selected);
                        }}
                        color="primary"
                    >
                        <IntlMessages id={`dialog.export`} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    });
};

export default ExportDialog;
