import {
  observable,
  action,
  computed,
  runInAction,
  reaction,
  decorate
} from 'mobx';

import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
//import { USER_ROLE_COPARTNER } from 'services/webapi-client/constants';

import CommonStore from "modules/CommonStore";

import UserAPI from "./api"

class UsersStore extends CommonStore {

  rootStore: RootStoreInterface;

  apiClient: WebApi;

  managerStore: ManagerStore;

  accountAccessStore: AccountAccessStore;

  changeEmailStore: ChangeEmailStore;


  me = {};

  expires: string;

  userFetchingError: any;

  isUserSavingInProgress: boolean;

  userSavingError: string;

  clientIp: string;

  clientIpFetchingError: any | undefined;

  isBalanceFetchingInProcess = false;

  unreadSupportMessagesCount: number;


  isUserInfoFetched: false;

  constructor(rootStore: RootStoreInterface) {
    const apiClient = new UserAPI(rootStore.apiClient);

    super(rootStore, apiClient);
    this.rootStore = rootStore;
    this.specification = rootStore.specifications["Users"];

    this.me = {};
    //    this.changeEmailStore = new ChangeEmailStore(rootStore);

    // hanlde successfull login as user
    /*
    reaction(
      () => this.rootStore.modulesStore.authStore.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.fetchUserInfo();
        }
      },
    );
    */

    /*
    if ( this.rootStore.modulesStore.authStore.isAuthenticated ) {
      console.log("!!!!!!!!!!!!!!!!", 2)
      this.fetchUserInfo();
    }
    */



    //TODO: ivan commented it
    /*
    // handle user fetching error
    reaction(
      () => this.userFetchingError,
      (userFetchingError) => {
        if (userFetchingError) {
          const { response } = userFetchingError;
          if (!response || response.status !== 401) {
            return;
          }

          if (this.rootStore.modulesStore.authStore.isUnderImpersonate) {
            this.rootStore.modulesStore.authStore.escapeImpersonate();
          } else {
            this.rootStore.modulesStore.authStore.fetchClientAccessToken();
          }
        }
      },
    );
    */


  }






  async fetchSettings(id): Promise<void> {
    runInAction(() => {
      this.fetchingSettingsError = '';
      this.isFetchingSettingsInProgress = false;
    });

    try {
      const data = await this.apiClient.getSettings();
      runInAction(() => {
        this.settings = this.recordHandlers({ ...data });
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.fetchingSettingsError = err;
        this.isFetchingSettingInProgress = false;
      });
    }
  }


  async saveSettings(params): Promise<void> {
    runInAction(() => {
      this.savingSettingsError = '';
      this.isSavingSettingsInProgress = false;
    });

    try {
      const data = await this.apiClient.saveSettings({ ...params });
      runInAction(() => {
        this.settings = this.recordHandlers({ ...data });
      });
      return data;
    } catch (err) {
      runInAction(() => {
        this.savingSettingsError = err;
        this.isSavingSettingInProgress = false;
      });
    }
  }




  async fetchSupportUnreadMessagesCount(): Promise<void> {
    try {
      const { total } = await this.apiClient.getUnreadSupportMessagesCount();
      runInAction(() => {
        this.unreadSupportMessagesCount = total;
      });
    } catch (err) {
      console.error(err);
    }
  }



  async fetchUserInfo(): Promise<void> {
    runInAction(() => {
      this.isUserInfoFetched = false;
      console.log("Fetching user info");
    });

    console.log(22);
    try {
      const data = await this.apiClient.getMe();
      runInAction(() => {
        this.me = { ...data };
        this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, "record", { id: data.id });
        this.isUserInfoFetched = true;
        console.log("User info fetched");
      });
    } catch (err) {
      runInAction(() => {
        this.userFetchingError = err;
        this.isUserInfoFetched = true;
        console.log("Problem fetching user info: " + err);

      });
    }
    console.log(333333);
  }

  async saveUserInfo(params: {}): Promise<void> {
    runInAction(() => {
      this.isUserSavingInProgress = true;
      this.userSavingError = undefined;
    });

    try {
      const response = await this.apiClient.putMe(params);
      runInAction(() => {
        this.me = { ...response };
        this.isUserSavingInProgress = false;
      });
    } catch (err) {
      runInAction(() => {
        this.isUserSavingInProgress = false;
        const validationErrors = getValidationErrors(err);
        this.userSavingError = validationErrors ? validationErrors.contacts : err;
      });
    }
  }


  async recordUpdated(record) {
    if (this.me.id && record.id === this.me.id) {
      this.me = { ...record };
    }
  }

  get homePage() {
    return this.me?.homePage || '/app/analytics'
  }
  get locale(): string {
    return this.me && this.me.lang && this.me.lang.locale ? this.me.lang.locale : "ru_RU";
  }
  get role(): string {
    return this.me && this.me.role && this.me.role.name ? this.me.role.name : undefined;
  }
  get isAdmin(): boolean {
    return this.role === 'admin';
  }

}


decorate(UsersStore, {
  me: observable,
  isUserInfoFetched: observable,

  userFetchingError: observable,
  isUserSavingInProgress: observable,
  userSavingError: observable,
  clientIp: observable,
  clientIpFetchingError: observable,
  isBalanceFetchingInProcess: observable,
  unreadSupportMessagesCount: observable,

  fetchSupportUnreadMessagesCount: action,
  fetchUserInfo: action,
  saveUserInfo: action,

  role: computed,
  locale: computed,
  isAdmin: computed,
  homePage: computed
});

export default UsersStore;
