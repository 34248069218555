import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError, IAxiosRetryConfig } from "axios-retry";
import qs from "qs";
//import Auth from './Auth/index.js';
//import User from './User/index.js';
//import Partner from './Partner';
import { NotificationContainer, NotificationManager } from "react-notifications";

const SAFE_HTTP_METHODS = ["get", "head", "options"];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(["put", "delete"]);

/**
 * Проверка строки на JSON
 * @param str
 * @returns {boolean}
 */
const isJSON = (str) => {
    try {
        return JSON.parse(str) && !!str;
    } catch (e) {
        return false;
    }
};

/**
 * Проверка конфига запроса на то, что это запрос получения токена
 * @param config
 * @returns {*}
 */
const isTokenRequest = (config) => {
    if (config.method !== "post") {
        return false;
    }

    return config && config.url && config.url.includes("/auth/token");
};

const isTimeoutHappened = (error) =>
    error.code === "ECONNABORTED" && error.message.toLocaleLowerCase().indexOf("timeout") !== -1;

const isRetryableError = (error) =>
    isTimeoutHappened(error) || !error.response || (error.response.status >= 500 && error.response.status <= 599);

const isIdempotentRequestError = (error) =>
    IDEMPOTENT_HTTP_METHODS.indexOf(error.config && error.config.method ? error.config.method : "") !== -1;

/**
 * Универсальный парсер ошибок axios запросов
 * @param err
 * @returns {string}
 */
export const getErrorMessage = (err) => {
    if (!err) {
        return "Unknown error";
    }
    if (err.response) {
        if (err.response.data.message) {
            return `${err.response.data.message}`;
        }

        if (err.response.data.errors) {
            const firstErrorField = Object.keys(err.response.data.errors)[0];
            if (Array.isArray(err.response.data.errors[firstErrorField])) {
                return `${err.response.data.errors[firstErrorField][0].message}`;
            }
            return `${err.response.data.errors[firstErrorField]}`;
        }

        if (err.response.status === 400) {
            return "Bad request";
        }

        if (err.response.status === 401) {
            return "Unauthorized";
        }

        if (err.response.status === 404) {
            return "Not found";
        }

        if (err.response.status === 403) {
            return "Forbidden";
        }
    } else if (err.request) {
        return "Connection refused";
    } else if (err.message && err.message !== "") {
        return `${err.message}`.replace(/Error: /g, "");
    } else if (err !== "") {
        return `${err}`.replace(/Error: /g, "");
    }
    return "Server error";
};

export const getValidationErrors = (err) => {
    if (!err || !err.response) {
        return null;
    }

    const {
        data: { errors },
    } = err.response;
    if (!errors) {
        return null;
    }

    return Object.keys(errors).reduce((acc, field) => {
        return {
            ...acc,
            [field]: errors[field][0].message,
        };
    }, {});
};

class WebApi {
    api = undefined;
    auth = undefined;
    user = undefined;
    projectApi = undefined;
    //partner = undefined;
    jti = "";
    token = "";
    axiosProps = undefined;

    constructor(props) {
        const { baseURL, clientId, clientSecret, timeout } = props;
        this.axiosProps = {
            baseURL,
            timeout: timeout || 60000,
            withCredentials: true,
            paramsSerializer: (params) => qs.stringify(params),
        };

        this.api = axios.create({
            baseURL,
            timeout: timeout || 60000,
            withCredentials: true,
            paramsSerializer: (params) => qs.stringify(params),
        });

        //this.auth = new Auth(this, { clientId, clientSecret });
        //this.user = new User(this);

        /*
        const ProjectAPI = require(`./${process.env.REACT_APP_PROJECT}/index`).default;
        this.projectApi = new ProjectAPI(this);
         */

        //        this.partner = new Partner(this.api);
        this.jti = "";

        this.api.interceptors.request.use(
            (config) => ({
                ...this.transformRequest(config),
            }),
            (err) => Promise.reject(err),
        );

        /*
                axiosRetry(this.api, {
                    retries: 3,
                    shouldResetTimeout: true,
                    retryDelay: (retryCount) => retryCount * 300,
                    retryCondition: (error) => {

                        alert(error);
                        return false;
                        if (isNetworkOrIdempotentRequestError(error)) {
                            return true;
                        }

                        if (!error.config || !isRetryableError(error)) {
                            return false;
                        }

                        if (isIdempotentRequestError(error)) {
                            // Повторная отправка всех запросов с методом, отличным от POST
                            return true;
                        }
                        return false;
                        // Если это запрос получения токена - разрешена повторная отправка
                        return isTokenRequest(error.config);
                    },
                });
                */
    }

    async fetch(config) {
        let result = "";
        try {
            //console.log({...this.axiosProps, ...config});
            result = await this.api.request(config);
        } catch (err) {
            if (err.response.status === 403 || err.response.status === 401) {
                localStorage.clear();
                document.location.reload();
                return;
            }
            const error = err.response && err.response.data ? err.response.data.error : err.response;

            NotificationManager.error("Error: " + getErrorMessage(error));
            throw getErrorMessage(error);
        }
        if (
            (result.status !== 200 || (config.returnBody !== true && result.data.status !== "success")) &&
            !result.config.url.match(/\/auth\/info\/domain/) &&
            !result.config.url.match(/\/auth\/token/)
        ) {
            console.error(result);
            NotificationManager.error("Error: " + JSON.stringify(result));
            throw Error(result);
        }
        return config.returnBody ? result.data : result.data.data;
    }

    /*

    setToken(token) {
        this.token = token;
    }

     */
    async checkToken() {
        if (!window.localStorage.getItem("admin_access_token")) return false;
        try {
            await this.fetch({ method: "get", url: `/auth/token` });
        } catch (err) {
            if (err === "Invalid token") {
                return false;
            }
        }
        return true;
    }

    setJti(jti) {
        this.jti = jti;
    }

    async fetchSpecifications() {
        const result = await this.api.get(`/specifications`);
        if (result.status !== 200 || result.data.status !== "success") {
            throw Error(result);
        }
        return result.data.data;
    }

    /**
     * Метод добавляет content-type и форматирует тело post и put запроса в json.
     * Подставляет csrf токен к запросам типа post, put, delete
     * Необходимо для того, чтобы можно было повторно отправлять запрос из axiosRetry, т.к.
     * библиотека отключает любые трансформации запроса, в том числе автоматическое добавление
     * content type и приведение объекта к json в axios
     * @param axiosConfig
     * @returns {*}
     */
    transformRequest(axiosConfig) {
        const { method, url, data, params } = axiosConfig;

        let requestData = isJSON(data) ? JSON.parse(data) : data;
        requestData = requestData || {};

        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
            csrfToken: this.jti,
        };
        if (window.localStorage.getItem("admin_access_token")) {
            headers["Authorization"] = "Bearer " + window.localStorage.getItem("admin_access_token");
        }
        if (window.localStorage.getItem("websocket_uuid")) {
            headers["Web-Socket-Id"] = window.localStorage.getItem("websocket_uuid");
        }

        return {
            ...axiosConfig,
            headers,
            params,
            data: JSON.stringify(requestData),
        };
    }
}

export default WebApi;
