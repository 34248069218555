import React from "react";
import Footer from "components/Footer";
import { isIOS, isMobile } from "react-device-detect";
import BelowNavHeader from "./Header/BelowNavHeader";
import Header from "../Vertical/Header";
import TopNav from "./Header/TopNav";
import SideBar from "components/SideBar";

const AboveHeaderNav = (props) => {
    if (isIOS && isMobile) {
        document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
        document.body.classList.remove("ios-mobile-view-height");
    }

    return (
        <div className="app-container">
            <SideBar />
            <div className="app-main-container">
                <div className="app-header app-header-horizontal">
                    <Header />

                    {(process.env.REACT_APP_PROJECT === "zakaut" || process.env.REACT_APP_PROJECT === "taxi") && (
                        <TopNav />
                    )}
                </div>

                <main className="app-main-content-wrapper">
                    <div className="app-main-content">{props.children}</div>
                    <Footer />
                </main>
            </div>
        </div>
    );
};

export default AboveHeaderNav;
