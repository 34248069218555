import CommonCRUD from "modules/CommonCRUD";

class Customers extends CommonCRUD {
    api = undefined;

    schema = "customers";

    constructor(api) {
        super(api);
        this.api = api;
    }

    async changeAdmin({ customer_id, user_id }) {
        const { customer } = await this.api.fetch({
            method: "post",
            url: `/${this.schema}/${customer_id}/change_admin`,
            data: {
                user_id,
            },
        });
        return customer;
    }
}

export default Customers;
