import React from "react";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";

const Footer = () => {
    const date = new Date();
    return (
        <footer className="app-footer">
            <span className="d-inline-block">
                Copyright {process.env.REACT_APP_PROJECT_TITLE} &copy; {date.getFullYear()}
            </span>
        </footer>
    );
};
export default Footer;
