import React, { useEffect, useState } from "react";
import { reaction } from "mobx";
import { useStores } from "modules/stores";

const VkAuth = (props) => {
    const { authStore } = useStores();
    const code = props.location.search.replace(/\?code=/g, "");

    if (code) {
        setTimeout(async () => {
            const result = await authStore.userSignInVkOAuth(
                process.env.REACT_APP_VK_OAUTH_CLIENT_ID,
                process.env.REACT_APP_VK_OAUTH_REDIRECT_URL,
                code,
            );
            if (result && result.access_token) {
                localStorage.setItem("vk-auth-result", 1);
                window.close();
            }
        }, 10);
    }

    return <div>Loading...</div>;
};

export default VkAuth;
