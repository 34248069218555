import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";

export default ({ size, record }) => {
    if (!record) return <div></div>;

    const status = record.online ? record.online : record.user && record.user.online ? record.user.online : "";

    return (
        <div className={`chat-avatar-mode`}>
            <Avatar className={`size-${size ? size : 60}`} src={record.avatar} alt={record.name}>
                {record.name ? record.name.substr(0, 2).toUpperCase() : ""}
            </Avatar>
            {status === "online" || status === "away" ? (
                <span className={`chat-mode status-size-${size ? size : 60} small ${status}`} />
            ) : (
                ""
            )}
        </div>
    );
};
