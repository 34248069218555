import React from "react";
import { List } from "@material-ui/core";
import NavMenuItem from "./NavMenuItem";
import NavSection from "./NavSection";
import NavCollapse from "./NavCollapse";
import { useStores } from "modules/stores";

const Navigation = (props) => {
    const { usersStore } = useStores();

    const { menuItems } = props;

    return (
        <List component="nav" disablePadding className="side-nav-menu">
            {menuItems.map((item, index) => {
                if (item.roles && item.roles.length && !item.roles.includes(usersStore.role)) {
                    return null;
                }

                switch (item.type) {
                    case "section":
                        return <NavSection {...item} key={index} />;
                    case "collapse":
                        return <NavCollapse {...item} key={index} />;
                    case "item":
                        return <NavMenuItem {...item} key={index} />;
                }
            })}
        </List>
    );
};

export default Navigation;
