import React, { useState, useEffect } from "react";
import Dropzone from "react-fine-uploader/dropzone";
import FineUploaderTraditional from "fine-uploader-wrappers";
import Avatar from "components/table/Avatar";
import FileInput from "react-fine-uploader/file-input";

const AvatarUpload = (props) => {
    const { store } = props;

    const uploader = new FineUploaderTraditional({
        options: {
            request: {
                customHeaders: {
                    Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
                },
                endpoint: `${process.env.REACT_APP_API_BASE_URL}/${store.specification.url}/${store.record.id}/avatar`,
            },
            chunking: {
                enabled: true,
            },

            callbacks: {
                onComplete: () => {
                    store.record.reload();
                },
            },
        },
    });

    //onClick={handleFetchRecord}
    return (
        <Dropzone style={{ height: 90, width: 90 }} uploader={uploader} dropActiveClassName="avatar_dropzone_active">
            <FileInput accept="image/*" uploader={uploader}>
                <Avatar size={90} record={store.record} />
            </FileInput>
        </Dropzone>
    );
};

export default AvatarUpload;
