import { decorate, observable, action, runInAction } from 'mobx';
import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
//import { StoreInterface, RootStoreInterface } from 'interfaces';
import CommonStore from "modules/CommonStore";
import TariffsAPI from "./api"

class citiesStore extends CommonStore {

  constructor(rootStore: RootStoreInterface) {
    const apiClient = new TariffsAPI(rootStore.apiClient);

    super(
      rootStore,
      apiClient,
      rootStore.specifications["CitiesProvidersPriority"]
    );
  }



}


decorate(citiesStore, {

});



export default citiesStore;
