import AnalyticsStore from "./Analytics/stores/AnalyticsStore";
import CustomersStore from "./Customers/stores/CustomersStore";
import CustomersReportsStore from "./CustomersReports/stores/CustomersReportsStore";
import CustomersFilesStore from "./Customers/stores/CustomersFilesStore";
import CustomersSubdivisionsStore from "./Customers/stores/CustomersSubdivisionsStore";
import CustomersContractsStore from "./Customers/stores/CustomersContractsStore";

import OrdersStore from "./Orders/stores/OrdersStore";
import OrdersClassesStore from "./Orders/stores/OrdersClassesStore";
import OrdersProvidersInfoStore from "./Orders/stores/OrdersProvidersInfoStore";
import OrdersLogStore from "./Orders/stores/OrdersLogStore";
import OrdersCostsStore from "./Orders/stores/OrdersCostsStore";
import ServicesStore from "./Services/stores/ServicesStore";
import ServicesStatusesStore from "./Services/stores/ServicesStatusesStore";
import ManagersStore from "./Managers/stores/ManagersStore";
import OperatorsStore from "./Operators/stores/OperatorsStore";
import AccessTokensStore from "./AccessTokens/stores/AccessTokensStore";
import AdminsStore from "./Admins/stores/AdminsStore";
import ProvidersStore from "./Providers/stores/ProvidersStore";
import ProvidersGroupsStore from "./Providers/stores/ProvidersGroupsStore";
import CitiesProvidersStore from "./Providers/stores/CitiesProvidersStore";
import CitiesProvidersPriorityStore from "./Providers/stores/CitiesProvidersPriorityStore";
import ProvidersClassesSettingsStore from "./Providers/stores/ProvidersClassesSettingsStore";
import TariffsStore from "./Tariffs/stores/TariffsStore";
import TariffsRegionsStore from "./Tariffs/stores/TariffsRegionsStore";
import TariffsGroupsStore from "./Tariffs/stores/TariffsGroupsStore";
import TariffsPriceTakerStore from "./Tariffs/stores/TariffsPriceTakerStore";
import TariffsZoneToZoneStore from "./Tariffs/stores/TariffsZoneToZoneStore";
import TariffsServicesStore from "./Tariffs/stores/TariffsServicesStore";
import CitiesStore from "./Cities/stores/CitiesStore";
import CitiesGroupsStore from "./Cities/stores/CitiesGroupsStore";
import CitiesAirportsStore from "./Cities/stores/CitiesAirportsStore";

import CoordinatorsStore from "./Coordinators/stores/CoordinatorsStore";
import HelpRequestsStore from "./HelpRequests/stores/HelpRequestsStore";
import EmployeesStore from "./Employees/stores/EmployeesStore";
import EmployeesJournalStore from "./Employees/stores/EmployeesJournalStore";
import RolesStore from "./Roles/stores/RolesStore";
import RolesCostCentersStore from "./Roles/stores/RolesCostCentersStore";
import RolesTimeSlotsStore from "./Roles/stores/RolesTimeSlotsStore";
import RolesLocationsStore from "./Roles/stores/RolesLocationsStore";

export default (rootStore) => {
    rootStore.addStore("analyticsStore", new AnalyticsStore(rootStore));
    rootStore.addStore("managersStore", new ManagersStore(rootStore));
    rootStore.addStore("operatorsStore", new OperatorsStore(rootStore));
    rootStore.addStore("accesstokensStore", new AccessTokensStore(rootStore));
    rootStore.addStore("adminsStore", new AdminsStore(rootStore));
    rootStore.addStore("providersStore", new ProvidersStore(rootStore));
    rootStore.addStore("providersGroupsStore", new ProvidersGroupsStore(rootStore));
    rootStore.addStore("citiesProvidersStore", new CitiesProvidersStore(rootStore));
    rootStore.addStore("citiesProvidersPriorityStore", new CitiesProvidersPriorityStore(rootStore));
    rootStore.addStore("providersClassesSettingsStore", new ProvidersClassesSettingsStore(rootStore));
    rootStore.addStore("customersStore", new CustomersStore(rootStore));
    rootStore.addStore("customersReportsStore", new CustomersReportsStore(rootStore));
    rootStore.addStore("customersFilesStore", new CustomersFilesStore(rootStore));
    rootStore.addStore("customersSubdivisionsStore", new CustomersSubdivisionsStore(rootStore));
    rootStore.addStore("customersContractsStore", new CustomersContractsStore(rootStore));
    rootStore.addStore("ordersStore", new OrdersStore(rootStore));
    rootStore.addStore("ordersCostsStore", new OrdersCostsStore(rootStore));
    rootStore.addStore("ordersLogStore", new OrdersLogStore(rootStore));
    rootStore.addStore("ordersClassesStore", new OrdersClassesStore(rootStore));
    rootStore.addStore("ordersProvidersInfoStore", new OrdersProvidersInfoStore(rootStore));
    rootStore.addStore("servicesStore", new ServicesStore(rootStore));
    rootStore.addStore("servicesStatusesStore", new ServicesStatusesStore(rootStore));
    rootStore.addStore("tariffsStore", new TariffsStore(rootStore));
    rootStore.addStore("tariffsRegionsStore", new TariffsRegionsStore(rootStore));
    rootStore.addStore("tariffsGroupsStore", new TariffsGroupsStore(rootStore));
    rootStore.addStore("tariffsPriceTakerStore", new TariffsPriceTakerStore(rootStore));
    rootStore.addStore("tariffsZoneToZoneStore", new TariffsZoneToZoneStore(rootStore));
    rootStore.addStore("tariffsServicesStore", new TariffsServicesStore(rootStore));
    rootStore.addStore("citiesStore", new CitiesStore(rootStore));
    rootStore.addStore("citiesGroupsStore", new CitiesGroupsStore(rootStore));
    rootStore.addStore("citiesAirportsStore", new CitiesAirportsStore(rootStore));
    rootStore.addStore("employeesStore", new EmployeesStore(rootStore));
    rootStore.addStore("helpRequestsStore", new HelpRequestsStore(rootStore));
    rootStore.addStore("coordinatorsStore", new CoordinatorsStore(rootStore));
    rootStore.addStore("employeesJournalStore", new EmployeesJournalStore(rootStore));
    rootStore.addStore("rolesStore", new RolesStore(rootStore));
    rootStore.addStore("rolesCostCentersStore", new RolesCostCentersStore(rootStore));
    rootStore.addStore("rolesTimeSlotsStore", new RolesTimeSlotsStore(rootStore));
    rootStore.addStore("rolesLocationsStore", new RolesLocationsStore(rootStore));
};
