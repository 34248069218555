export const loadFile = (link, fileName = "report") => {
    const downloadFile = () => {
        const element = document.createElement("a");
        element.setAttribute("href", link);
        element.setAttribute("download", fileName);
        element.style.display = "none";

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    downloadFile();
};
