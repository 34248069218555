import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import DetailPageInfoCard from "components/table/DetailPageInfoCard";
import Friends from "components/profile/Friends/index";
import TimerView from "components/dashboard/Common/TimerView/index";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../../../../components/table/ConfirmDialog";
import { useStores } from "modules/stores";

const Detail = (props) => {
    const { authStore } = useStores();
    const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);

    const { store } = props;
    const {
        store: { record },
    } = props;

    const handleUpdateRecord = async (data) => {
        store.record.update(data);
    };
    const resetPassword = () => {
        setConfirmDialogOpened(false);
        authStore.resetPasswordAndSendAdmin(record?.details?.user_id || record?.id || props?.id);
    };

    return useObserver(() => (
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <DetailPageInfoCard fields={["name", "email", "phone", "sms_code"]} store={store} />
                <Button
                    variant="contained"
                    className="jr-btn text-white bg-blue jr-btn-lg"
                    onClick={() => setConfirmDialogOpened(true)}
                >
                    <span>Сбросить пароль</span>
                </Button>

                <ConfirmDialog
                    open={confirmDialogOpened}
                    onClose={() => {
                        setConfirmDialogOpened(false);
                    }}
                    onAgree={resetPassword}
                    title="Вы действительно хотите сбросить пароль?"
                />
            </div>
        </div>
    ));
};

export default Detail;
