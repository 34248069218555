import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import SidebarListCell from "components/table/SidebarListCell";
import SearchBox from "components/SearchBox/index";
import Error from "components/Errors/Error.js";
import CustomScrollbars from "util/CustomScrollbars";
import SwipeableViews from "react-swipeable-views";
import CircularProgress from "components/CircularProgress";
import { useIntl } from "react-intl";

const SidebarList = (props) => {
    const { store } = props;

    const [filter, setFilter] = useState(props.filter);
    const [currentPage, setCurrentPage] = useState(0);

    const { formatMessage } = useIntl();

    useEffect(() => {
        //if use cache true. then it's not loaded for direct open detail page.
        loadData();
    }, []);

    const loadData = (isAppend = false) => {
        store.fetchList(false, filter, "mtime desc", currentPage, 20, true, isAppend);
    };

    useEffect(() => {
        loadData();
    }, [filter]);

    const searchHandle = (ev) => {
        setFilter({ ...filter, $nodx_search: ev.target && ev.target.value ? ev.target.value : "" });
    };

    const handleLoadMore = () => {
        if (!store.isFetchingListInProgress) {
            setCurrentPage((currentPage) => currentPage + 1);
            loadData(true);
        }
    };

    return useObserver(() => {
        //console.log("FILTER-werrwerwer", props.filter)

        //TODO:
        const width = 1000;

        return (
            <div className="chat-sidenav-main">
                {/*
                <div className="chat-sidenav-header">
                    <Link to={props.backUrl} className="ml-1">
                        &laquo; <IntlMessages id="sidebar.backLink"/>
                    </Link>
                </div>
                */}

                <div className="chat-sidenav-header">
                    <div className="search-wrapper">
                        <SearchBox
                            value={store.filter["$nodx_search"]}
                            placeholder={formatMessage({ id: `search.placeholder` })}
                            onChange={searchHandle}
                        />
                    </div>
                </div>

                <div className="chat-sidenav-content">
                    <SwipeableViews>
                        <CustomScrollbars
                            className="chat-sidenav-scroll scrollbar"
                            style={{ height: width >= 1200 ? "calc(100vh - 323px)" : "calc(100vh - 202px)" }}
                            loadMore={handleLoadMore}
                        >
                            <div className="chat-user">
                                {store.fetchingListError && <Error>{store.fetchingListError}</Error>}
                                {!store.fetchingListError &&
                                    store.list &&
                                    store.list &&
                                    store.list.map((item, index) => (
                                        <SidebarListCell
                                            key={index}
                                            item={item}
                                            store={store}
                                            onSelect={(item) => {
                                                props.onSelect(item.id);
                                            }}
                                        />
                                    ))}
                                {store.isFetchingListInProgress && <CircularProgress />}
                            </div>
                        </CustomScrollbars>
                    </SwipeableViews>
                </div>
            </div>
        );
    });
};

export default SidebarList;
