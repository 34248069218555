import Dashboard from "./dashboard";

export default [
    {
        title: "Сводка",
        route: "/",
        component: Dashboard,
        match: "^$",
    },
];
