import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useStores } from "../modules/stores";

export default ({ component: Component, ...rest }) => {
    const { authStore } = useStores();

    return (
        <Route
            {...rest}
            render={(props) =>
                authStore.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth/signin",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
