const languageData = [
    {
        id: 1,
        languageId: "russian",
        locale: "ru",
        name: "Russian",
        icon: "ru",
    },
    {
        id: 2,
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "us",
    },
];
export default languageData;
