import React from "react";
import { useStores } from "modules/stores";
import DataTable from "components/table/DataTable";

const UsersList = (params) => {
    const { usersStore } = useStores();

    return (
        <div className="app-wrapper">
            <DataTable detailUrl="/app/users" store={usersStore} />
        </div>
    );
};

export default UsersList;
