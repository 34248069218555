import { decorate, observable, action, runInAction } from "mobx";
import WebApi, { getErrorMessage, getValidationErrors } from "modules/WebApi";
//import { StoreInterface, RootStoreInterface } from 'interfaces';
import CommonStore from "modules/CommonStore";
import ApiClient from "./api";

class CustomersStore extends CommonStore {
    constructor(rootStore) {
        const apiClient = new ApiClient(rootStore.apiClient);
        super(rootStore, apiClient, rootStore.specifications["Customers"]);
    }
    changingAdminError = "";
    changingAdminProgress = false;
    admin = null;

    async changeAdmin(customer_id, user_id) {
        runInAction(() => {
            this.changingAdminError = "";
            this.changingAdminProgress = true;
        });
        try {
            const data = await this.apiClient.changeAdmin({ customer_id, user_id });
            runInAction(() => {
                this.admin = data;
                this.changingAdminError = "";
                this.changingAdminProgress = false;
            });
            return data;
        } catch (e) {
            runInAction(() => {
                this.admin = null;
                this.changingAdminError = e;
                this.changingAdminProgress = false;
            });
            return null;
        }
    }
}

decorate(CustomersStore, {});

export default CustomersStore;
