import { decorate, observable, action, runInAction } from "mobx";
import WebApi, { getErrorMessage, getValidationErrors } from "modules/WebApi";
import CommonStore from "modules/CommonStore";
import OrdersCostsAPI from "./api";

class OrdersCostsStore extends CommonStore {
    changingError = "";
    isChangingInProgress = "";
    constructor(rootStore) {
        const apiClient = new OrdersCostsAPI(rootStore.apiClient);

        super(rootStore, apiClient, rootStore.specifications["OrdersCosts"]);
    }

    async changeCosts(id, record) {
        runInAction(() => {
            this.changingError = "";
            this.isChangingInProgress = false;
        });

        try {
            const data = await this.apiClient.change(id, record);
            this.dispatch(data, "update");
            runInAction(() => {
                this.isChangingInProgress = false;
            });
            return data;
        } catch (err) {
            runInAction(() => {
                this.changingError = err;
                this.isChangingInProgress = false;
            });
        }
    }
}

decorate(OrdersCostsStore, {});

export default OrdersCostsStore;
