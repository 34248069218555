import { decorate, observable, action, runInAction } from 'mobx';
import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
//import { StoreInterface, RootStoreInterface } from 'interfaces';

const reportRelatedTypes = ['process_report', 'ready_report', 'crash_report']

class WebSocketStore implements StoreInterface {
    rootStore: RootStoreInterface;

    apiClient = undefined;

    isOpened = false;

    isAuthorized = false;

    isInitializing = false;

    ws = undefined;

    subscriptions = [];

    constructor(rootStore: RootStoreInterface, apiClient) {
        this.rootStore = rootStore;
        setInterval(() => { this.setupWebSocket(); }, 60 * 1000);
        this.setupWebSocket();
    }


    subscribe(moduleName, type, filter) {
        //TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!check maybe user already subscribed to this filter
        runInAction(() => {
            this.subscriptions = this.subscriptions.filter(subscription => subscription.moduleName !== moduleName || subscription.type !== type);
            this.subscriptions.push({ moduleName, type, filter });
        })
        if (this.isOpened && this.ws && this.ws.readyState && this.isAuthorized) {
            this.ws.send(JSON.stringify({
                type: 'subscribe',
                payload: { moduleName, type, filter }
            }));
        }
    }



    wssAuth() {
        if (!localStorage.getItem("admin_access_token")) {
            return;
        }
        if (!this.isOpened || (this.ws && !this.ws.readyState) || this.isAuthorized) return;
        runInAction(() => {
            this.isAuthorized = true;
        });

        const msg = {
            type: 'authenticate',
            payload: { token: localStorage.getItem("admin_access_token") }
        }
        console.log("WSS", "Authorization");
        //alert("CONNECTED");
        try {
            this.ws.send(JSON.stringify(msg));
            //resend all subscriptions
            setTimeout(() => {
                this.subscriptions.forEach(subscription => {
                    this.ws.send(JSON.stringify({ type: 'subscribe', payload: subscription }));
                });
            }, 1000);
        } catch (e) {
            console.error("WSS", "Couldn't auth websocket", e);
        }
    }

    setupWebSocket() {
        return;

        if (!this.rootStore.modulesStore.authStore || !this.rootStore.modulesStore.authStore.isAuthenticated) return;
        if (this.isOpened) return;
        if (this.isInitializing) return;

        runInAction(() => {
            this.isInitializing = true;
        });
        /*
        if ( this.ws ) {
            this.ws.close();
            delete this.ws;
        }
         */

        this.ws = new WebSocket(process.env.REACT_APP_WSS);
        this.ws.onmessage = ({ data }) => {
            let json = JSON.parse(data);
            console.log("WSS", "NEW MESSAGE", json);
            console.log("WSS", this.ws.readyState);
            if (json.type === 'uuid') {
                window.localStorage.setItem("websocket_uuid", json.payload.uuid);
            }

            if (json.type === 'dispatch') {
                console.log("DISPATCH", "WEBSOCKET RECIEVED", json.payload.moduleName, json.payload.item);
                Object.keys(this.rootStore.modulesStore).forEach(storeName => {
                    const store = this.rootStore.modulesStore[storeName];
                    if (store.specification && store.specification.moduleName === json.payload.moduleName) {
                        console.log("WSS", "DISPATCH", "FOUND", store.specification.moduleName);
                        store.dispatch(json.payload.item)
                    }
                })
            }
            /*
            let find = false;
            let newQueue = state.queue.map((it) => {
                if (it.id !== newItem.id) {
                    return it;
                }
                find = true;
                return newItem;
            });
            if (!find) {
                newQueue = [newItem, ...newQueue];
            }
            setQueue(newQueue);
            */
        };

        const interval = setInterval(() => {
            console.log("PING");
            try {
                if (this.isOpened) {
                    this.ws.send(JSON.stringify({ type: "ping" }));
                } else {
                    console.log("reinistialize websocket");
                    clearInterval(interval);
                    setTimeout(() => {
                        if (!this.isOpened) {
                            this.setupWebSocket();
                        }
                    }, 10 * 1000);
                }
            } catch (e) {
                console.error("PINg error", e);
            }
        }, 60 * 1000);


        this.ws.onopen = ({ }) => {
            console.log("WSS", "ONOPEN");
            runInAction(() => {
                this.isOpened = true;
                this.isInitializing = false;

            });
            this.wssAuth();
        }

        this.ws.onclose = ({ }) => {
            console.log("WSS", "RECONNECT");
            runInAction(() => {
                this.isOpened = false;
                this.isAuthorized = false;
                this.isInitializing = false;
            });
            this.setupWebSocket();
            //alert(11);
        }
    }

    /*
        subscribe (scheme, id) {
            this.ws.send (JSON.stringify({
                type   : 'dispatch',
                payload: {
                    schema: 'psychologists',
                    id: 1
                }
            }));
        }
        */

}




decorate(WebSocketStore, {

    setupWebSocket: action,
    wssAuth: action,
    isOpened: observable,
    isAuthorized: observable,
});


export default WebSocketStore;


