import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from "mobx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import IntlMessages from "util/IntlMessages";
import { Alert } from "reactstrap";
import FileBase64 from "react-file-base64";
import { useIntl } from "react-intl";
import Error from "components/Errors/Error";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EditField from "./EditField";

const EditDialog = (props) => {
    const { store } = props;

    const [form, setForm] = useState({});

    useEffect(() => {
        reaction(
            () => store?.editingRecord,
            (editingRecord) => {
                setForm(store?.editingRecord);
                //console.log("!!!!!!!!!!!!!!!!!!! FORM", JSON.stringify(form));
            },
        );
    }, []);

    const handleFormChange = (ev) => {
        setForm({ ...form, [ev.target.name]: ev.target.value });
        //console.log("!!!!!!!!!!!!!!!!!!! FORM 2", JSON.stringify(form));
    };

    return useObserver(() => {
        return (
            <Dialog open={props.open} TransitionComponent={Slide} onClose={props.onClose}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="secondary">
                        <IntlMessages id={`dialog.cancel`} />
                    </Button>
                    <Button onClick={props.onAgree} color="primary">
                        <IntlMessages id={`dialog.confirm`} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    });
};

export default EditDialog;
