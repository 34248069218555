import React from "react";
import { Alert } from "reactstrap";
import IntlMessages from "util/IntlMessages";

const Error = (props) => {
    return (
        <Alert className="shadow-lg animated slideInUpTiny animation-duration-3" color="danger">
            <b>
                <IntlMessages id={"error"} />
            </b>
            : {props.children}
        </Alert>
    );
};

export default Error;
