import { decorate, observable, action, runInAction } from 'mobx';
import WebApi, { getErrorMessage, getValidationErrors } from 'modules/WebApi';
import CommonStore from "modules/CommonStore";
import TariffsAPI from "./api"

class ordersLogStore extends CommonStore {
  constructor(rootStore: RootStoreInterface) {
    const apiClient = new TariffsAPI(rootStore.apiClient);

    super(
        rootStore,
        apiClient,
        rootStore.specifications["OrdersLog"]
    );
  }
}

decorate(ordersLogStore, {});

export default ordersLogStore;
