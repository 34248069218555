import React, { useEffect, useState } from "react";
import SearchBar from "material-ui-search-bar";

export const useDebouncedEffect = (effect, deps, delay, before = () => {}) => {
    useEffect(() => {
        before();
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
    }, [...(deps || []), delay]);
};

const SearchBox = ({ styleName, placeholder, onChange, name, value }) => {
    /*
      <div>
          <SearchBar
              value={value}
              onChange={onChange}
              onRequestSearch={() => doSomethingWith(this.state.value)}

          />
    </div>
    */
    const [val, setVal] = useState(value);

    useDebouncedEffect(
        () => {
            if (val !== value && onChange) {
                onChange({ target: { value: val } });
            }
        },
        [val],
        1000,
    );

    return (
        <div className={`search-bar right-side-icon bg-transparent ${styleName}`}>
            <div className="form-group">
                <input
                    className="form-control border-0"
                    type="search"
                    placeholder={placeholder}
                    onChange={(e) => setVal(e.target.value)}
                    value={val}
                    name={name}
                />

                <button className="search-icon">
                    <i className="zmdi zmdi-search zmdi-hc-lg" />
                </button>
            </div>
        </div>
    );
};
export default SearchBox;

SearchBox.defaultProps = {
    styleName: "",
    value: "",
};
