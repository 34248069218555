import { AxiosInstance } from 'axios';
import CommonCRUD from "modules/CommonCRUD";
//import { IpsToAllowAccessResponse } from 'components/AccountAccess/IPsToAllowAccess';
//import { LoginHistoryResponse } from 'components/AccountAccess/LoginHistory';
//import { IpsToDisableCaptchaResponse } from 'components/AccountAccess/IPsToDisableCaptcha';


class Index extends CommonCRUD {
    api: AxiosInstance;

    schema = 'users';

    constructor(api) {
        super(api);
        this.api = api;
    }



    async getMe() {
        if (!window.localStorage.getItem("admin_access_token") || window.localStorage.getItem("admin_access_token") === "") return;
        return await this.api.fetch({
            method: 'get',
            url: `/${this.schema}/me`
        });
    }

    async putMe(data) {
        return await this.api.fetch({
            method: 'put',
            url: `/${this.schema}/me`,
            data
        });
    }



    async getSettings() {
        return await this.api.fetch({
            method: 'get',
            url: `/${this.schema}/me/settings`,
        });
    }


    async saveSettings(data) {
        return await this.api.fetch({
            method: 'put',
            url: `/${this.schema}/me/settings`,
            data
        });
    }

    /*
    async putMe(params: {useDirectLinks?: boolean}): Promise<object> {
        const { data } = await this.api.put('/userss/me', params);
        return data;
    }

    async getCoPartner() {
        const response = await this.api.get('/copartner/me');
        return response.data;
    }

    async requestResetPassword(params: {
        email: string,
        backUrl: string,
        captchaType?: string,
        captchaValue?: string,
    }) {
        const { email, backUrl, captchaType, captchaValue } = params;
        const response = await this.api.post('/users/me/reset-password', {
            email,
            backUrl,
            captchaType,
            captchaValue,
        });
        return response.data;
    }

    async requestChangeEmail(params: {
        email: string,
        backUrl: string,
        password: string,
    }) {
        const { email, backUrl, password } = params;
        const response = await this.api.post('/users/me/change-email', {
            email,
            backUrl,
            password,
        });
        return response.data;
    }

    async changeEmail(params: { code: string }) {
        const { code } = params;
        const response = await this.api.put('/users/me/change-email', {
            code,
        });
        return response.data;
    }

    async resetPassword(params: {
        code: string,
        password: string,
        captchaType: string,
        captchaValue: string,
    }) {
        const { code, password, captchaType, captchaValue } = params;
        const response = await this.api.put('/users/me/reset-password', {
            code,
            password,
            captchaType,
            captchaValue,
        });
        return response.data;
    }

    async verifyEmail(code: string) {
        const response = await this.api.put('/users/me/verify-email', {
            code,
        });

        return response.data;
    }

    async getBalance() {
        const { data } = await this.api.get('/users/me/balance');
        return data;
    }

    async getUnreadSupportMessagesCount(): Promise<UnreadMessageResponse> {
        const { data } = await this.api.get('/support/me/unread');
        return data;
    }

    async getAccessToPromoCodes(): Promise<AccessToPromoCodesResponse> {
        const { data } = await this.api.get('/partner/me/promo-codes/access');
        return data;
    }

    async getManager(): Promise<UserManagerInterface> {
        const { data } = await this.api.get('/users/me/manager');
        return data;
    }

    async getClientIp(): Promise<UserClientIpInterface> {
        const { data } = await this.api.get('/users/me/ip');
        return data;
    }

    async addIpsToAllowAccess({ ip, netmask }: { ip: string; netmask: number }): Promise<void> {
        const { data } = await this.api.post('/users/me/security/allow-ip', {
            ip,
            netmask,
        });

        return data;
    }

    async getIpsToAllowAccess({ limit, offset }: { limit: number; offset: number }):
        Promise<IpsToAllowAccessResponse> {
        const { data } = await this.api.get('/users/me/security/allow-ip', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }

    async deleteIpsToAllowAccess(id: string): Promise<IpsToAllowAccessResponse> {
        const { data } = await this.api.delete(`/users/me/security/allow-ip/${id}`);
        return data;
    }

    async addIpsToDisableCaptcha({ ip }: { ip: string}): Promise<any> {
        const { data } = await this.api.post('/users/me/security/disable-captcha', { ip });
        return data;
    }

    async getIpsToDisableCaptcha({ limit, offset }: { limit: number; offset: number }):
        Promise<IpsToDisableCaptchaResponse> {
        const { data } = await this.api.get('/users/me/security/disable-captcha', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }

    async deleteIpsToDisableCaptcha(id: string): Promise<any> {
        const { data } = await this.api.delete(`/users/me/security/disable-captcha/${id}`);
        return data;
    }

    async getLoginHistory({ limit, offset }: { limit: number; offset: number }):
        Promise<LoginHistoryResponse> {
        const { data } = await this.api.get('/users/me/login-history', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }
    */
}

export default Index;
