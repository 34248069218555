import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {
    let scrollBar;

    const [oldScrollTop, setOldScrollTop] = useState(-1);
    const handleUpdate = (values) => {
        if (props.onUpdate) {
            props.onUpdate(values);
        }

        if (props.loadMore) {
            const { scrollTop, scrollHeight, clientHeight } = values;
            if (scrollTop === 0) return;
            const pad = 100; // 100px of the bottom
            // t will be greater than 1 if we are about to reach the bottom
            const t = (scrollTop + pad) / (scrollHeight - clientHeight);

            if (scrollTop === oldScrollTop) return;
            setOldScrollTop(scrollTop);

            if (t > 1) {
                props.loadMore();
            }
        }
    };

    return (
        <Scrollbars
            {...props}
            onUpdate={handleUpdate}
            ref={(e) => {
                if (e) scrollBar = e;
            }}
            autoHide
            renderTrackHorizontal={(props) => (
                <div {...props} style={{ display: "none" }} className="track-horizontal" />
            )}
        />
    );
};

export default CustomScrollbars;
