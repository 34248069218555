//import enProjectMessages from '../locales/zakaut/en_US.json';
import enCommonMessages from "../locales/en_US.json";

const enProjectMessages = require(`../locales/${process.env.REACT_APP_PROJECT}/en_US.json`);

const EnLang = {
    messages: {
        ...enCommonMessages,
        ...enProjectMessages,
    },
    locale: "en-US",
};
export default EnLang;
