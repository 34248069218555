import React, { useState, useEffect } from "react";
import { useStores } from "modules/stores";
import { useObserver } from "mobx-react-lite";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import CardMenu from "components/dashboard/Common/CardMenu";
import moment from "moment";

const TimerView = (props) => {
    const {
        modulesStore: { psychologistsCalendarStore: store },
    } = useStores();

    useEffect(() => {
        store.fetchList(
            false,
            { ...props.filter, ...{ start: [">=", moment().format("YYYY-MM-DD 00:00")] } },
            "start asc",
            0,
            5,
        );
    }, []);

    moment.locale("ru_RU");

    const { headerColor } = props;
    return useObserver(() => {
        return (
            <div className="jr-card">
                <div className={`jr-card-header-color text-center bg-${headerColor}`}>
                    <div className="jr-card-header-top jr-card-timer-header">
                        <IconButton className="jr-menu-icon mr-auto" aria-label="Menu">
                            <span className="menu-icon bg-white" />
                        </IconButton>
                    </div>

                    <Avatar className="bg-grey lighten-2 avatar-shadow size-90 mx-auto jr-card-timer-avatar">
                        <h1 className="m-0 text-primary font-weight-bold">{moment().format("DD")}</h1>
                    </Avatar>

                    <div className="jr-card-hd-content text-white">
                        <h2 className="text-white jr-font-weight-medium mb-1">{moment().format("dddd")}</h2>
                        <p className="mb-0">{moment().format("MMMM, YYYY")}</p>
                    </div>
                </div>
                <div className="jr-card-body">
                    <div className="d-flex flex-column">
                        {!store.list || !store.list.length ? "Нет событий" : ""}

                        {store.list &&
                            store.list.map((item) => {
                                const today = moment(item.start).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD");
                                return (
                                    <div className="list-line-item">
                                        <div className={`list-line-badge bg-${today ? "danger" : "primary"}`} />

                                        <div className="list-line-content">
                                            <h4 className={`mb-1 text-${today ? "danger" : "primary"}`}>{item.name}</h4>
                                            <p className="jr-fs-sm text-light">
                                                {moment(item.start).format("YYYY-MM-DD HH:mm")}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        );
    });
};

export default TimerView;
