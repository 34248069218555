import React from "react";
import { useObserver } from "mobx-react-lite";
import { useStores } from "modules/stores";
import DetailPageInfoCard from "components/table/DetailPageInfoCard";

const Detail = (props) => {
    const { mailerStore: store } = useStores();

    return useObserver(() => {
        return (
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                    <DetailPageInfoCard fields={["name", "subject", "html"]} store={store} />
                </div>
            </div>
        );
    });
};

export default Detail;
