import React from "react";
import { useStores } from "modules/stores";
import { useObserver } from "mobx-react-lite";

export default ({ color }) => {
    const { authStore, usersStore } = useStores();

    return useObserver(() => {
        if (process.env.REACT_APP_PROJECT === "healthylab") {
            return (
                <div>
                    <img
                        src={
                            authStore.domainInfo && authStore.domainInfo.avatar
                                ? authStore.domainInfo.avatar
                                : require("assets/images/healthylab_logo.png")
                        }
                        alt="logo"
                        title="logo"
                    />
                    <div className="logo-title">
                        {authStore.domainInfo ? authStore.domainInfo.name : process.env.REACT_APP_PROJECT_TITLE}
                    </div>
                </div>
            );
        }

        if (process.env.REACT_APP_PROJECT === "zakaut") {
            return (
                <div>
                    <div style={{ fill: color ? color : "#fff" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="155 0 266 266">
                            <rect className="cls-1" x="265.04" y="205.61" width="13.71" height="13.71" />
                            <rect className="cls-1" x="287.04" y="205.61" width="13.71" height="13.71" />
                            <rect className="cls-1" x="265.04" y="227.61" width="13.71" height="13.71" />
                            <rect className="cls-1" x="287.04" y="227.61" width="13.71" height="13.71" />
                            <path
                                className="cls-1"
                                d="M356.19,174.41A190,190,0,0,1,366,226c9.09-28.55,10.92-56.21,5.07-78.94-5.1-19.82-18.16-43.48-34.94-63.28-18.32-21.63-34.9-44.64-52-72.2C271.59,28.49,270.21,63,269.37,84l-.11,2.67c-.39,9.56.58,18.24,1.61,27.43,1.85,16.49,3.92,35-2,62.54,18.22-19.49,25.68-54.27,22.61-72.07l-.92-5.39,4.43,3.19C312,114.58,347.92,150,356.19,174.41Z"
                            />
                            <path
                                className="cls-1"
                                d="M294.36,133.15a118.39,118.39,0,0,1-8.45,25.54,75.14,75.14,0,0,0,12.13,18A162.67,162.67,0,0,1,294.36,133.15Z"
                            />
                            <path
                                className="cls-1"
                                d="M264.79,86.52l.11-2.66c.6-14.94,1.49-36.5,6.42-54.37a565.64,565.64,0,0,1-40.5,54.27C214,103.56,201,127.22,195.88,147c-5.85,22.73-4,50.39,5.07,78.94a190.38,190.38,0,0,1,9.78-51.57c7.36-21.68,36.59-52.1,54.9-67.13A144,144,0,0,1,264.79,86.52Z"
                            />
                        </svg>
                    </div>
                    <div style={{ fill: color ? color : "#fff" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 290 666 100">
                            <path
                                className="cls-2"
                                d="M64.08,310.83H41.79q-7.41,0-10.19.81-4.92,1.46-6.83,8.4a51.68,51.68,0,0,0-1.16,6.31l-2.26-.23,2.26-20.67H82.73L33,379.1H60.55a49.75,49.75,0,0,0,7.9-.47A13.55,13.55,0,0,0,73.17,377a17.21,17.21,0,0,0,7-8.45,36.52,36.52,0,0,0,1.74-6l2.32.23-4.29,21.77H14.17Z"
                            />
                            <path
                                className="cls-2"
                                d="M144,304.64l27.27,63.63q3.25,7.59,5.45,10.31a8.92,8.92,0,0,0,7.24,3.76h1.62v2.14H152.31v-2.26h2c4.4,0,6.6-1.48,6.6-4.46q0-2.25-2.08-7.58l-4.52-11.64H125.39l-5,12.57q-2.09,5.2-2.09,6.71a3.94,3.94,0,0,0,2,3.36,9.67,9.67,0,0,0,5,1h1.44v2.26h-26.4v-2.14c3.09-.08,5.27-.46,6.54-1.16a11.24,11.24,0,0,0,4.29-4.81q1-2,3.13-6.71l28.19-65Zm-16.27,49h24.61l-12.68-28.37Z"
                            />
                            <path
                                className="cls-2"
                                d="M224.39,346.21v25.94c0,4,.65,6.66,2,8.05s3.28,1.95,6.26,2l2.83.12v2.14H201.05v-2.14l2.72-.12c2.9-.07,4.94-.75,6.14-2s2-4.08,2-8.05V317.78q0-6-2-8c-1.2-1.27-3.24-2-6.14-2l-2.72-.11v-2.14h34.39v2.14l-2.31.17a16.11,16.11,0,0,0-5.16.76,5.87,5.87,0,0,0-3.06,3.87,21.1,21.1,0,0,0-.52,5.39V343.2q14-12.45,20.72-20.85,7.24-8.91,7.24-11.58a2.31,2.31,0,0,0-1.16-2.2q-1.27-.75-5.73-.92v-2.2h29v2.2l-1.62.11a17.31,17.31,0,0,0-9,3.24q-2.49,1.8-8.63,8.52-7.29,8.1-11.87,12.85-5.85,6-7.82,8.16l27.22,28.2Q271,377.36,276.26,380a20.94,20.94,0,0,0,9,2.38v2.14H247.37v-2.14l2-.12c3.24-.11,4.86-1.14,4.86-3.07q0-2.37-8.1-10.65Z"
                            />
                            <path
                                className="cls-2"
                                d="M339.33,304.64l27.27,63.63q3.24,7.59,5.44,10.31a8.92,8.92,0,0,0,7.24,3.76h1.62v2.14H347.67v-2.26h2c4.4,0,6.6-1.48,6.6-4.46q0-2.25-2.09-7.58l-4.51-11.64h-28.9l-5,12.57q-2.08,5.2-2.08,6.71a3.92,3.92,0,0,0,2,3.36,9.61,9.61,0,0,0,5,1h1.45v2.26h-26.4v-2.14c3.08-.08,5.27-.46,6.54-1.16a11.29,11.29,0,0,0,4.28-4.81q1-2,3.13-6.71l28.2-65Zm-16.27,49h24.61L335,325.25Z"
                            />
                            <path
                                className="cls-2"
                                d="M440.43,307.59v-2.14h27.21v2.14l-2.72.11q-4.34.12-6.14,2-2,2.08-2,8v35.49a53.79,53.79,0,0,1-1.11,12.22,24.39,24.39,0,0,1-9,14.07q-7.35,5.73-21,5.73-18.82,0-26.46-12.51-3.18-5.26-3.76-14.3-.12-2.25-.12-13.72v-27q0-6-2-8c-1.2-1.27-3.24-2-6.14-2l-2.72-.11v-2.14h34.16v2.14l-2.72.11q-4.33.12-6.14,2-2,2.08-2,8V356q0,7.47,1.15,11.18a16.09,16.09,0,0,0,7.76,9.55,22.9,22.9,0,0,0,11.29,2.78,25.15,25.15,0,0,0,15.46-4.92q5.38-4,6.89-11.75a48.75,48.75,0,0,0,.76-9.5V317.78q0-6-2-8c-1.2-1.27-3.24-2-6.14-2Z"
                            />
                            <path
                                className="cls-2"
                                d="M502,384.48v-2.14l2.6-.12c3-.07,5.06-.75,6.26-2s2-4.08,2-8.05V311.24H502.21c-3.94,0-6.83.5-8.69,1.5a10,10,0,0,0-5.21,6.78,51.44,51.44,0,0,0-.69,5.27l-2.26-.12,1.16-19.22H551.6l1.16,19.22-2.26.12a50.74,50.74,0,0,0-.7-5.27,10,10,0,0,0-5.21-6.78c-1.81-1-4.71-1.5-8.68-1.5h-10.6v60.91c0,4,.66,6.66,2,8.05s3.32,1.95,6.25,2l2.61.12v2.14Z"
                            />
                        </svg>
                    </div>
                </div>
            );
        }

        if (process.env.REACT_APP_PROJECT === "taxi") {
            if (usersStore.role === "customer" || usersStore.role === "employee") {
                return (
                    <div>
                        <div
                            style={{
                                color: "#666",
                                fontSize: "16px",
                                fontFamily: "Roboto",
                            }}
                        >
                            {usersStore.me.detail.name}
                        </div>
                    </div>
                );
            } else {
                return "";
            }
        }

        if (process.env.REACT_APP_PROJECT === "moondo") {
            return (
                <div>
                    <div
                        style={{
                            color: "#666",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                        }}
                    >
                        {usersStore.me.detail.name}
                    </div>
                </div>
            );
        }
    });
};
