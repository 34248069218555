import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";

import SignIn from "./SignIn";
import Verify from "./Verify";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";

import VkAuth from "./VkAuth";

const Routes = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/vk-auth`} component={VkAuth} />
            <Route path={`${match.url}/verify/:uuid`} component={Verify} />
            <Route path={`${match.url}/verify`} component={Verify} />

            <Route path={`${match.url}/forgot/:code`} component={ForgotPassword} />
            <Route path={`${match.url}/forgot`} component={ForgotPassword} />
            <Route path={`${match.url}/signup/:code`} component={SignUp} />
            <Route path={`${match.url}/signup`} component={SignUp} />
            <Route path={`${match.url}/signin/:code`} component={SignIn} />
            <Route path={`${match.url}/signin`} component={SignIn} />
            <Route path={`${match.url}/`} component={SignIn} />
        </Switch>
    );
};

export default withRouter(Routes);
