import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from "mobx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import IntlMessages from "util/IntlMessages";
import { Alert } from "reactstrap";
import FileBase64 from "react-file-base64";
import { useIntl } from "react-intl";
import Error from "components/Errors/Error";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EditField from "./EditField";
import Dropzone from "react-fine-uploader/dropzone";
import FineUploaderTraditional from "fine-uploader-wrappers";
import FileInput from "react-fine-uploader/file-input";
import { saveFile } from "util/helpers";

const EditDialog = (props) => {
    const { store } = props;

    const [form, setForm] = useState({});
    const [imported, setImported] = useState(-1);
    const [failed, setFailed] = useState(-1);
    const [error, setError] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        reaction(
            () => store.editingRecord,
            (editingRecord) => {
                setForm(store.editingRecord);
                //console.log("!!!!!!!!!!!!!!!!!!! FORM", JSON.stringify(form));
            },
        );
    }, []);

    const handleFormChange = (ev) => {
        setForm({ ...form, [ev.target.name]: ev.target.value });
        //console.log("!!!!!!!!!!!!!!!!!!! FORM 2", JSON.stringify(form));
    };

    const uploader = new FineUploaderTraditional({
        options: {
            request: {
                customHeaders: {
                    Authorization: "Bearer " + localStorage.getItem("admin_access_token"),
                },
                endpoint: `${process.env.REACT_APP_API_BASE_URL}/${store.specification.url}/import`,
            },
            chunking: {
                enabled: true,
            },

            callbacks: {
                onComplete: (id, name, response) => {
                    if (response && response.status === "failed") setError(response.error);

                    if (response && response.status === "success") {
                        setImported(response.data.imported);
                        setDescription(response.data.description);
                        setFailed(response.data.failed);
                    }
                },
            },
        },
    });

    const handleClose = () => {
        setError("");
        setImported(-1);
        props.onClose();
    };

    const downloadSample = async () => {
        const data = await store.fetchImportSample(props.fileType);
        if (data) saveFile(store.specification.moduleName + "." + props.fileType, data);
    };

    return useObserver(() => {
        return (
            <Dialog open={props.open} TransitionComponent={Slide} onClose={props.onClose}>
                <DialogTitle>
                    <IntlMessages id="dialog.import.title" />
                </DialogTitle>

                <DialogContent>
                    {error !== "" ? <div>{error}</div> : null}
                    {imported !== -1 ? <div>Импортировано записей: {imported}</div> : null}
                    {failed !== -1 ? <div>Пропущено записей: {failed}</div> : null}
                    {error === "" && imported === -1 ? (
                        <DialogContentText>
                            <Dropzone
                                className="import-dropzone"
                                uploader={uploader}
                                dropActiveClassName="import-dropzone-active"
                            >
                                <FileInput accept="application/vnd.*" uploader={uploader}>
                                    <div>Нажмите или перетащите файл в эту область</div>
                                </FileInput>
                            </Dropzone>

                            <div className="import-dropzone-example" onClick={downloadSample}>
                                Пример файла для импорта
                            </div>
                        </DialogContentText>
                    ) : (
                        ""
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        <IntlMessages id={`dialog.close`} />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    });
};

export default EditDialog;
