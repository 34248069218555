import React, { useState, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from "mobx";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { FormControl, InputLabel, Input, FormHelperText } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import IntlMessages from "util/IntlMessages";
import { Alert } from "reactstrap";
import FileBase64 from "react-file-base64";
import { useIntl } from "react-intl";
import Error from "components/Errors/Error";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EditField from "./EditField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles, Theme, createStyles } from "@material-ui/core/styles";

/*
const useStyles = makeStyles((theme: Theme) => ({
    .MuiTab-root {
    min-width: 128px !important
}
}));
*/

const EditDialog = ({
    store,
    fields,
    filter,
    open,
    onClose,
    onSave,
    saveTitle,
    onChange,
    description,
    customEditFieldsRenderer,
    intlPrefix,
    backdrop = true,
    position = "center",
    maxWidth = "sm",
    hideFilterFields = true,
    ignoreInitedValues = false,
}) => {
    const [form, setForm] = useState({});
    const [saveForm, setSaveForm] = useState({});

    if (filter && filter["$nodx_search"]) {
        delete filter["$nodx_search"];
    }
    useEffect(() => {
        reaction(
            () => store.editingRecord,
            (editingRecord) => {
                // to save only updates
                setForm(store.editingRecord);
                if (store.editingRecord.id) {
                    setSaveForm({ id: store.editingRecord.id });
                } else {
                    setSaveForm(store.editingRecord);
                }
            },
        );
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(saveForm);
        }
    }, [saveForm]);

    const handleFormChange = (field, value, ev, afterInit) => {
        let setFormValue;
        if (field.type === "list") {
            const num = ev.target.name; // TODO
            setFormValue = (_form) => {
                const list = form[field.name] ? [...form[field.name]] : []; // this is correct!
                list[num] = value;
                return {
                    ..._form,
                    ...(ignoreInitedValues
                        ? {
                              ...(afterInit
                                  ? {}
                                  : {
                                        [field.name]: list.filter((item) => item !== ""),
                                    }),
                          }
                        : {
                              [field.name]: list.filter((item) => item !== ""),
                          }),
                };
            };
        } else {
            setFormValue = (_form) => ({
                ..._form,
                ...(ignoreInitedValues
                    ? {
                          ...(afterInit
                              ? {}
                              : {
                                    [field.name]: value,
                                }),
                      }
                    : {
                          [field.name]: value,
                      }),
            });
        }
        setForm(setFormValue);
        setSaveForm(setFormValue);

        if (onChange) {
            onChange(setFormValue);
        }
    };

    const renderField = (field, key) => {
        if (filter && filter[field.name] && hideFilterFields) return;

        if (field.formVisible === false) return;
        if (typeof field.formVisible === "object") {
            const fieldValue =
                form[field.formVisible.field] && form[field.formVisible.field].name
                    ? form[field.formVisible.field].name
                    : form[field.formVisible.field];
            /*if (field.formVisible.value !== undefined) { //TODO!!!!!!! change to equal

                if (Array.isArray(field.formVisible.value)) {
                    if (!field.formVisible.value.includes(fieldValue)) {
                        return;
                    }
                } else if (form[field.formVisible.field] != field.formVisible.value) { // don't use !== here
                    return;
                }
            } else */
            if (field.formVisible.equal !== undefined) {
                if (Array.isArray(field.formVisible.equal)) {
                    if (!field.formVisible.equal.includes(fieldValue)) {
                        return;
                    }
                } else if (form[field.formVisible.field] != field.formVisible.equal) {
                    // don't use !== here
                    return;
                }
            } else if (field.formVisible.notequal !== undefined) {
                //TODO!!!!!!! change to equal
                if (Array.isArray(field.formVisible.notequal)) {
                    if (field.formVisible.notequal.includes(fieldValue)) {
                        return;
                    }
                } else if (
                    form[field.formVisible.field] == field.formVisible.notequal ||
                    (form[field.formVisible.field] === undefined && field.formVisible.notequal == "")
                ) {
                    // don't use !== here
                    return;
                }
            }
        }

        if (field.formVisible === "add" && form.id !== undefined) {
            return;
        }
        if (field.formVisible === "edit" && form.id === undefined) {
            return;
        }

        /*

        if ( field.formInvisible === true ) return;
        if ( typeof(field.formInvisible) === "object" ) {

            if (Array.isArray(field.formInvisible.value)) {
                if (field.formInvisible.value.includes(form[field.formInvisible.field])) {
                    return;
                }
            } else if (field.formInvisible.value && form[field.formInvisible.field] == field.formInvisible.value) { //don't use !== here
                return;
            } else if (!field.formInvisible.value && !form[field.formInvisible.field]) {
                return;
            }
        }
         */
        if (customEditFieldsRenderer && customEditFieldsRenderer[field.name]) {
            return customEditFieldsRenderer[field.name](field, handleFormChange, form && form[field.name]);
        }

        return (
            <EditField
                key={key}
                filter={{
                    ...(filter || {}),
                    ...(field && field.depend_on && form[field.depend_on] && form[field.depend_on] !== -1
                        ? { [field.depend_on]: form[field.depend_on] }
                        : {}),
                }}
                intlPrefix={intlPrefix}
                store={store}
                field={field}
                value={form[field.name]}
                onChange={handleFormChange}
                disabled={(field && field.depend_on && !form[field.depend_on]) || form[field.depend_on] === -1}
            />
        );
    };

    const renderFieldGroup = (groupName) => (
        <fieldset className="edit-form-dialog-fieldset">
            <legend>
                <IntlMessages id={`${intlPrefix || store.specification.moduleName}.schema.${groupName}`} />
            </legend>
            {fields.map((fieldName) =>
                store.specification.fields
                    .filter((field) => field.name === fieldName && field.group == groupName)
                    .map((field, key) => renderField(field, key)),
            )}
        </fieldset>
    );

    const isContainGroup = store.specification.fields.filter((field) => field.group).length > 0;
    const firstGroup = isContainGroup ? store.specification.fields.filter((field) => field.group)[0].group : null;
    const [currentTab, setCurrentTab] = React.useState(firstGroup);

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

    return useObserver(() => {
        const groupsRendered = {};
        const groupsTitlesRendered = {};

        /*
        if (field.group) {
            if (!groupsRendered[field.group]) {
                groupsRendered[field.group] = 1;
                return renderFieldGroup(field.group);
            }
        }
    }))}
    */
        return (
            <Dialog
                BackdropProps={!backdrop ? { style: { backgroundColor: "transparent" } } : {}}
                PaperProps={position !== "center" ? { style: { position: "absolute", ...position } } : {}}
                style={!backdrop ? { position: "inherit" } : {}}
                open={open}
                onClose={onClose}
                TransitionComponent={Slide}
                fullWidth
                maxWidth={maxWidth}
                disableEnforceFocus
            >
                <DialogTitle>
                    <IntlMessages
                        id={`${intlPrefix || store.specification.moduleName}.${form.id ? "edit" : "add"}.title`}
                    />
                </DialogTitle>

                <DialogContent>
                    {store.addingError && <Error>{store.addingError}</Error>}
                    {description !== undefined && <DialogContentText>{description}</DialogContentText>}

                    {fields.map((fieldName) =>
                        store.specification.fields
                            .filter((field) => field.name === fieldName)
                            .filter((field) => !field.group)
                            .map((field, key) => {
                                return renderField(field, key);
                            }),
                    )}

                    {isContainGroup && (
                        <>
                            <Paper square>
                                <Tabs
                                    value={currentTab}
                                    onChange={handleChangeTab}
                                    aria-label="simple tabs example"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="full width tabs example"
                                >
                                    {fields.map((fieldName) =>
                                        store.specification.fields
                                            .filter((field) => field.name === fieldName)
                                            .filter((field) => field.group)
                                            .filter((field) => {
                                                if (!groupsTitlesRendered[field.group]) {
                                                    groupsTitlesRendered[field.group] = 1;
                                                    return true;
                                                }
                                                return false;
                                            })
                                            .map((field, key) => (
                                                <Tab
                                                    value={field.group}
                                                    label={
                                                        <IntlMessages
                                                            id={`${intlPrefix || store.specification.moduleName}.schema.${field.group}`}
                                                        />
                                                    }
                                                    id={`simple-tab-${field.group}`}
                                                    aria-controls={`simple-tabpanel-${field.group}`}
                                                />
                                            )),
                                    )}
                                </Tabs>
                            </Paper>
                            {fields.map((fieldName) =>
                                store.specification.fields
                                    .filter((field) => field.name === fieldName)
                                    .filter((field) => field.group)
                                    .filter((field) => {
                                        if (!groupsRendered[field.group]) {
                                            groupsRendered[field.group] = 1;
                                            return true;
                                        }
                                        return false;
                                    })
                                    .map((fieldBase, key) => (
                                        <div
                                            role="tabpanel"
                                            hidden={currentTab !== fieldBase.group}
                                            id={`simple-tabpanel-${fieldBase.group}`}
                                            aria-labelledby={`simple-tab-${fieldBase.group}`}
                                        >
                                            {currentTab === fieldBase.group && (
                                                <Typography style={{ marginTop: 10 }}>
                                                    {fields.map((fieldName) =>
                                                        store.specification.fields
                                                            .filter(
                                                                (field) =>
                                                                    field.name === fieldName &&
                                                                    field.group == fieldBase.group,
                                                            )
                                                            .map((field, key) => renderField(field, key)),
                                                    )}
                                                </Typography>
                                            )}
                                        </div>
                                    )),
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {onClose ? (
                        <Button onClick={onClose} color="default">
                            <IntlMessages id="dialog.cancel" />
                        </Button>
                    ) : null}
                    {onSave ? (
                        <Button
                            onClick={() => {
                                onSave(saveForm);
                            }}
                            color="primary"
                        >
                            {saveTitle || <IntlMessages id={`dialog.${form.id ? "save" : "add"}`} />}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        );
    });
};

export default EditDialog;
