import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import ExportDialog from "../../table/ExportDialog";
import { NotificationManager } from "react-notifications";
import { getErrorMessage } from "modules/WebApi";

export const ExportButton = observer(
    ({ filter = {}, fields, fileType = "xlsx", sortBy = "id desc", fileName = "report", store, ...rest }) => {
        const [visibleDialog, setVisibleDialog] = useState(false);

        const openDialog = () => setVisibleDialog(true);
        const closeDialog = () => setVisibleDialog(false);

        const isLoading = useMemo(() => {
            return store?.isFetchingExportInProgress || store.exportData;
        }, [store.isFetchingExportInProgress, store.exportData]);

        const handleExportData = useCallback(
            async (selectedFields) => {
                closeDialog();
                if (rest?.onClick) rest.onClick();
                await store.fetchExport(filter, sortBy, selectedFields, fileType);
                if (store.fetchingExportError) {
                    NotificationManager.error("Error: " + getErrorMessage(store.fetchingExportError));
                }
            },
            [store, filter, sortBy, fields, fileType, rest],
        );

        return (
            <>
                <Button
                    disabled={isLoading}
                    variant="contained"
                    {...rest}
                    className={`ml-3 jr-btn jr-btn-lg ${rest.className || ""}`}
                    onClick={openDialog}
                >
                    <i className="zmdi zmdi-download" />
                    <span>
                        <IntlMessages id={`button.export${isLoading ? "ing" : ""}`} />
                        {isLoading ? "..." : ""}
                    </span>
                </Button>
                <ExportDialog
                    open={visibleDialog}
                    onExport={handleExportData}
                    exportFields={fields}
                    store={store}
                    onClose={closeDialog}
                />
            </>
        );
    },
);
